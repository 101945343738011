import React, { FormEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import VacancyHead from './VacancyDisplayHead';
import VacancyTitle from './VacancyDisplayTitle';
import VacancyList from './VacancyDisplayList';
import VacancyItem from './VacancyDisplayItem';
import StyledLink from '../StyledComponents/StyledLink';
import StyledSelect from '../StyledComponents/StyledSelect';
import { useActions } from '../../../hooks/useActions';
import useTypedSelector from '../../../hooks/useTypedSelector';
import { ShortVacanciesItemInterface } from '../../../types/mainPage';
import { Vacancy } from '../../../types/vacancies';
import { VacancyDisplayShowAll } from '../../MainPage/ShortVacancies/ShortVacancies';

const VacancyDisplaySection = styled.div`
  margin-top: 15px;
`;

interface PropsInterface {
  modifiedItem: boolean;
}

interface SelectType {
  select: string;
  flag: boolean;
}

const VacancyDisplayContent: React.FC<PropsInterface> = ({ modifiedItem }) => {
  const [openedNav, setOpenedNav] = useState(false);
  const showMore = 10;

  const { vacancies, totalResult } = useTypedSelector(
    r => r.mainData.vacanciesNav,
  );

  const [select, setSelect] = useState<SelectType>({
    select: 'all',
    flag: false,
  });
  const { fetchVacanciesMain } = useActions();
  const [limit, setLimit] = useState<number>(showMore);
  const handleSelect = (e: React.FormEvent<HTMLSelectElement>) => {
    setSelect({ select: e.currentTarget.value, flag: !select.flag });
  };
  useEffect(() => {
    fetchVacanciesMain(limit, select.flag, true);
  }, [limit, select]);

  const handleOpenNav = () => {
    setOpenedNav(!openedNav);
  };
  return (
    <VacancyDisplaySection>
      <VacancyHead modifiedItem={false}>
        <VacancyTitle count={totalResult} modifiedItem>
          Вакансии
        </VacancyTitle>
        <select
          onChange={handleSelect}
          style={{
            width: '165px',
            height: '32px',
            padding: '5px 10px',
          }}
        >
          <option value="all">Все вакансии</option>
          <option value="active">Только активные</option>
        </select>
      </VacancyHead>
      <VacancyList modifiedItem id="fixedNavVacancy">
        {vacancies.map((n: Vacancy) => {
          return (
            <VacancyItem
              vacancyTitle={n.name}
              candidates={Number(n.text_conditions)}
              questionnaires={Number(n.type_schedule)}
              modifiedItem
              key={n.id}
            />
          );
        })}
      </VacancyList>
      {totalResult - limit > 0 && (
        <VacancyDisplayShowAll
          onClick={() => setLimit(prev => prev + showMore)}
        >
          <span>ещё</span>
          <span className="show-number">
            {totalResult - limit > 10 ? 10 : totalResult - limit}
          </span>
          вакансии
        </VacancyDisplayShowAll>
      )}
      <StyledLink
        text="Создать вакансию"
        path="/vacancies/add"
        handleСlick={handleOpenNav}
      >
        <svg
          width="15"
          height="14"
          viewBox="0 0 15 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <ellipse cx="7.60469" cy="7" rx="6.95674" ry="7" fill="black" />
          <path
            d="M7.60428 5V9M9.59192 7L5.61664 7"
            stroke="#FEDC2D"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      </StyledLink>
    </VacancyDisplaySection>
  );
};

export default VacancyDisplayContent;
