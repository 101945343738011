import React from 'react';
import styled from 'styled-components';
import { wordDeclination, wordsDict } from '../../../helpers/wordDeclination';

const StyledLi = styled.li<{
  fixedNavItem: boolean | undefined;
}>`
  display: flex;
  flex-wrap: wrap;
  padding: ${props =>
    props.fixedNavItem ? '22px 20px 22px 15px' : '20px 30px 20px 0'};
  position: relative;
  margin-top: ${props => (props.fixedNavItem ? '10px' : '0')};
  border: ${props => (props.fixedNavItem ? '1px solid #cdcede' : 'none')};
  border-radius: ${props => (props.fixedNavItem ? '8px' : '0')};
  transition: all 0.3s ease;
  cursor: pointer;

  &:first-child {
    margin-top: 0;
  }

  &:after {
    display: ${props => (props.fixedNavItem ? 'none' : 'block')};
    position: absolute;
    content: '';
    width: 238px;
    height: 1px;
    background-color: rgba(205, 206, 222, 0.7);
    bottom: 0px;
    left: 5px;
  }

  &:hover {
    border-color: ${props => (props.fixedNavItem ? '#fedc2d' : 'inherit')};
    box-shadow: ${props =>
      props.fixedNavItem ? '0 0 0 2px #fedc2d' : 'inherit'};
  }

  &:hover .vacancy-item__letter {
    color: ${props => (props.fixedNavItem ? '#000000' : 'inherit')};
    background-color: ${props => (props.fixedNavItem ? '#fedc2d' : 'inherit')};
  }

  &:hover .vacancy-item__title {
    color: ${props => (props.fixedNavItem ? '#000000' : 'inherit')};
  }
`;

const StyledLetter = styled.span<{
  fixedNavItem: boolean | undefined;
}>`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: ${props => (props.fixedNavItem ? '27px' : '40px')};
  height: ${props => (props.fixedNavItem ? '27px' : '40px')};
  margin-right: 15px;
  border-radius: 50%;
  font-weight: 700;
  font-size: ${props => (props.fixedNavItem ? '14px' : '20px')};
  line-height: ${props => (props.fixedNavItem ? '20px' : '29px')};
  color: #888e96;
  background: #f2f2f2;
  transition: all 0.3s ease;
`;

const StyledInfo = styled.span<{
  fixedNavItem: boolean | undefined;
}>`
  display: block;
  width: ${props =>
    props.fixedNavItem ? 'calc(100% - 42px)' : 'calc(100% - 55px)'};
`;

const StyledTitle = styled.span<{
  fixedNavItem: boolean | undefined;
}>`
  display: flex;
  align-items: center;
  min-height: ${props => (props.fixedNavItem ? '0' : '42px')};
  width: 100%;
  margin-bottom: ${props => (props.fixedNavItem ? '15px' : '10px')};
  font-weight: 500;
  color: ${props => (props.fixedNavItem ? '#828282' : 'inherit')};
  transition: all 0.3s ease;
`;

const StyledLinks = styled.span<{
  fixedNavItem: boolean | undefined;
}>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-right: ${props => (props.fixedNavItem ? '15px' : '0')};

  a {
    font-size: 13px;
    line-height: 17px;
    text-decoration: underline;
    color: #828282;
    transition: all 0.3s ease;

    &:hover {
      color: #333333;
    }
  }

  span {
    margin-left: ${props => (props.fixedNavItem ? '0' : '4px')};
  }

  &.archive {
    color: #828282;

    &:hover {
      color: #333333;
    }
  }
`;

interface PropsInterface {
  vacancyTitle: string;
  candidates: number;
  questionnaires: number;
  modifiedItem?: boolean;
  handleClick?: () => void;
}

const VacancyDisplayItem: React.FC<PropsInterface> = ({
  vacancyTitle,
  candidates,
  questionnaires,
  modifiedItem,
  handleClick,
}) => {
  return (
    <StyledLi fixedNavItem={modifiedItem} onClick={handleClick}>
      <StyledLetter
        fixedNavItem={modifiedItem}
        className="vacancy-item__letter"
      >
        {vacancyTitle.slice(0, 1)}
      </StyledLetter>
      <StyledInfo fixedNavItem={modifiedItem}>
        <StyledTitle
          fixedNavItem={modifiedItem}
          className="vacancy-item__title"
        >
          {vacancyTitle}
        </StyledTitle>
        <StyledLinks fixedNavItem={modifiedItem}>
          <a href="/">
            {candidates}{' '}
            {wordDeclination(candidates, wordsDict.CANDIDATES_FORMS)}
          </a>
          <a href="/">
            {questionnaires}{' '}
            {wordDeclination(questionnaires, wordsDict.QUESTIONNARIES_FORMS)}
          </a>
        </StyledLinks>
      </StyledInfo>
    </StyledLi>
  );
};

export default VacancyDisplayItem;
