import React from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
  position: relative;
  z-index: 1;
  display: block;
  width: 44px;
  height: 23px;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  cursor: pointer;
`;

const StyledLabel = styled.label`
  position: relative;
  display: block;
  width: 44px;
  height: 23px;
  border-radius: 19px;
  background-color: #e0e0e0;
  transition: background-color 0.15s ease-in-out;

  &::after {
    position: absolute;
    content: '';
    background-color: #fff;
    width: 17px;
    height: 17px;
    top: 3.4px;
    right: 3px;
    cursor: pointer;
    border-radius: 50%;
    transition: transform 0.3s ease-in-out;
  }
`;

const StyledInput = styled.input`
  &:checked ~ label {
    background-color: #27ae60;
  }

  &:checked + label::after {
    transform: translateX(-20px);
  }
`;

interface CustomStyles {
  paddingLeft: string | number;
}

interface PropsInterface {
  additionalClass?: string;
  id?: string;
  inlineStyles?: CustomStyles;
  handleChange?: () => void;
  checked?: any;
}

const StyledSwitch: React.FC<PropsInterface> = ({
  additionalClass,
  inlineStyles,
  id,
  handleChange,
  checked,
}) => {
  return (
    <StyledDiv className={additionalClass} style={inlineStyles}>
      <StyledInput
        className="visually-hidden"
        onChange={handleChange}
        id={id}
        type="checkbox"
        checked={checked}
      />
      <StyledLabel htmlFor={id} />
    </StyledDiv>
  );
};

export default StyledSwitch;
