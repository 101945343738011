import React from 'react';

import styled from 'styled-components';
import arrow from '../../../assets/image/arrow.svg';

const StyledSelectComponent = styled.select`
  display: block;
  width: 100%;
  outline: none;
  font-size: 15px;
  line-height: 21px;
  color: #333333;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  padding: 14px 17px;
  text-transform: none;
  border-radius: 5px;

  height: 49px;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(${arrow});
  background-size: 12px;
  background-position: calc(100% - 15px) center;
  background-repeat: no-repeat;

  &:hover,
  &:focus {
    border-color: #bdbdbd;
  }
`;

export const SelectDiv = styled.div`
  select {
    display: block;
    width: 100%;
    outline: none;
    font-size: 15px;
    line-height: 21px;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    padding: 14px 17px;
    text-transform: none;
    border-radius: 5px;

    height: 49px;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url(${arrow});
    background-size: 12px;
    background-position: calc(100% - 15px) center;
    background-repeat: no-repeat;

    &:hover,
    &:focus {
      border-color: #bdbdbd;
    }
  }
`;

interface CustomStyles {
  width?: string | number;
  height?: string | number;
  padding?: string | number;
  paddingRight?: string | number;
  fontSize?: string | number;
  lineHeight?: string | number;
  color?: string;
  borderColor?: string;
}

interface PropsInterface {
  additionalClass?: string;
  inlineStyles?: CustomStyles;
  name?: string;
  value?: string;
  defaultValue?: string;
  id?: string;
  handleChange?: any;
  disabled?: boolean;
}

const StyledSelect: React.FC<PropsInterface> = ({
  additionalClass,
  inlineStyles,
  children,
  name,
  value,
  handleChange,
  defaultValue,
  disabled,
  id,
}) => {
  return (
    <StyledSelectComponent
      className={additionalClass}
      style={inlineStyles}
      name={name}
      value={value}
      onChange={handleChange}
      defaultValue={defaultValue}
      disabled={disabled}
      id={id}
    >
      {children}
    </StyledSelectComponent>
  );
};

export default StyledSelect;
