import React, { useEffect, useState } from 'react';
import { Russian } from 'flatpickr/dist/l10n/ru';
import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import Loader from 'react-loader-spinner';
import produce from 'immer';
import { CVI } from '../../../types/vacancies';
import { addNewCv } from '../../../store/actions/vacancies';
import { DateTimeFormats } from '../../../helpers/formatEnum';

enum Actions {
  DELETE = 'DELETE',
  ADD = 'ADD',
  EDIT = 'EDIT',
}

const VacancyCVsRow: React.FC<{
  cv: CVI;
  handleChangeState: (
    action: Actions,
    index: number,
    value?: string,
    name?: string,
  ) => void;
  index: number;
}> = ({ cv, handleChangeState, index }) => {
  const handleChangeStateRow = (e: {
    target: { name: string; value: string };
  }) => {
    handleChangeState(Actions.EDIT, index, e.target.value, e.target.name);
  };

  return (
    <>
      <div className="row addResume-newPerson">
        <div className="col-lg-12">
          <button
            type="button"
            onClick={() => {
              handleChangeState(Actions.DELETE, index);
            }}
          >
            Удалить
          </button>
          <label className="addResume-title">
            Имя
            <input
              className="dflt-input"
              type="text"
              name="first_name"
              onChange={handleChangeStateRow}
              placeholder="Иванов Иван Иванович"
            />
          </label>
        </div>
        <div className="col-lg-12">
          <label className="addResume-title">
            Фамилия
            <input
              className="dflt-input"
              type="text"
              name="last_name"
              onChange={handleChangeStateRow}
              placeholder="Иванов Иван Иванович"
            />
          </label>
        </div>
      </div>
      <div className="row addResume-newPerson">
        <div className="col-lg-4">
          <label className="addResume-title">
            E-mail
            <input
              className="dflt-input"
              type="text"
              name="email"
              placeholder="info@mebot24-hr.ru"
              onChange={handleChangeStateRow}
            />
          </label>
        </div>
        <div className="col-lg-4">
          <label className="addResume-title" htmlFor="tel">
            Телефон
            <input
              className="dflt-input dflt-input_phone"
              type="text"
              onChange={handleChangeStateRow}
              name="phone"
              placeholder="+7 (___) ___-__-__"
            />
          </label>
        </div>
        <div className="col-lg-4">
          <label className="addResume-title" htmlFor="eventDate">
            Дата рождения
            <div className="addEvent-block addEvent-block__date">
              <Flatpickr
                name="year_of_birthday"
                onChange={(date, currentDateString) => {
                  handleChangeStateRow({
                    target: {
                      name: 'year_of_birthday',
                      value: moment(currentDateString, 'DD.MM.YYYY').format(
                        DateTimeFormats.DATE_FORMAT_API,
                      ),
                    },
                  });
                }}
                className="addEvent-block__input"
                options={{
                  locale: Russian,
                  dateFormat: 'd.m.Y',
                  mode: 'single',
                  inline: false,
                  defaultDate: cv.year_of_birthday,
                  disableMobile: true,
                }}
              />
            </div>
          </label>
        </div>
      </div>
      <div className="row addResume-newPerson">
        <div className="col-lg-12">
          <label className="addResume-title" htmlFor="link">
            Ссылка на резюме
          </label>
          <input
            className="dflt-input"
            id="link"
            type="text"
            name="url"
            onChange={handleChangeStateRow}
            placeholder="https://rabota.ua"
          />
        </div>
      </div>
    </>
  );
};

const VacancyCVsAdd: React.FC<{
  vacancyId: string;
  reFetchCVS: () => void;
  setModal: (e: boolean) => void;
}> = ({ vacancyId, setModal, reFetchCVS }) => {
  const [cv, setCv] = useState<CVI[]>([
    {
      vid: Math.random(),
      id: '',
      vacancy_id: vacancyId,
      first_name: '',
      last_name: '',
      url: '',
      year_of_birthday: moment()
        .subtract(18, 'years')
        .format(DateTimeFormats.DATE_FORMAT_API),
      phone: '',
    },
  ]);
  const [cvLoading, setCvLoading] = useState(false);

  const handleChangeState = (
    action: Actions,
    index?: number,
    value?: string,
    name?: string,
  ) => {
    setCv(
      produce(draft => {
        switch (action) {
          case Actions.EDIT: {
            if (index !== undefined && name) {
              draft[index][name] = value;
            }

            return draft;
          }
          case Actions.DELETE: {
            if (index !== undefined) {
              draft.splice(index, 1);
            }
            return draft;
          }
          case Actions.ADD: {
            draft.push({
              vid: Math.random(),
              id: '',
              vacancy_id: vacancyId,
              first_name: '',
              last_name: '',
              url: '',
              year_of_birthday: moment()
                .subtract(18, 'years')
                .format(DateTimeFormats.DATE_FORMAT_API),
              phone: '',
            });
            return draft;
          }

          default: {
            return draft;
          }
        }
      }),
    );
  };

  const handleAddNewCV = () => {
    setCvLoading(true);
    addNewCv(cv)
      .then(() => {
        setCvLoading(false);
        setModal(false);
        reFetchCVS();
      })
      .catch(error => {
        setCvLoading(false);
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(cv);
  }, [cv]);

  return (
    <>
      <div className="addEvent-title">Добавить резюме</div>
      <div
        className="collapse-item addResume-first"
        id="multiCollapseExample4"
        data-parent="#addResume"
      >
        <div className="row">
          {cv.map((item, i) => (
            <VacancyCVsRow
              key={item.vid}
              cv={item}
              index={i}
              handleChangeState={handleChangeState}
            />
          ))}
          <div className="col-lg-12">
            <button
              className="addResume-newRow"
              type="button"
              onClick={() => {
                handleChangeState(Actions.ADD);
              }}
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <ellipse
                  cx="6.95674"
                  cy="7"
                  rx="6.95674"
                  ry="7"
                  fill="#CDCEDE"
                />
                <path
                  d="M6.95639 5V9M8.94403 7L4.96875 7"
                  stroke="#F3F3F7"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
              Добавить еще одно
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <button
              type="button"
              className="dflt-bttn"
              onClick={handleAddNewCV}
              disabled={cvLoading}
              style={{ opacity: cvLoading ? 0.5 : 1 }}
            >
              Добавить резюме
              {cvLoading && (
                <Loader type="Audio" color="#000" height="16px" width="16px" />
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default VacancyCVsAdd;
