import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import StyledLink from '../StyledComponents/StyledLink';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'owl.carousel/dist/assets/owl.carousel.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'owl.carousel/dist/assets/owl.theme.default.css';
import bg1jpg from '../../../assets/image/important-slider-item-bg-1-1.jpg';
import bg1png from '../../../assets/image/important-slider-item-bg-1-2.png';
import acsl1 from '../../../assets/image/actions-slider-item-icon-1.svg';
import acsl2 from '../../../assets/image/actions-slider-item-icon-2.svg';
import acsl3 from '../../../assets/image/actions-slider-item-icon-3.svg';
import acsl4 from '../../../assets/image/actions-slider-item-icon-4.svg';

const style = {
  width: '260px',
  backgroundImage: `url(${bg1png}), url(${bg1jpg})`,
};

const carouselPropsImportant = {
  nav: true,
  autoWidth: true,
  dots: true,
  margin: 25,
  items: 4,
  navText: [
    "<svg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5 1C4.68 1.32 2.2 3.8 1 5L5 9' stroke='#828282' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></svg>",
    "<svg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1 1C1.32 1.32 3.8 3.8 5 5L1 9' stroke='#828282' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></svg>",
  ],
  responsive: {
    0: {
      nav: false,
      dots: false,
      margin: 15,
    },
    576: {
      nav: false,
      dots: false,
      margin: 25,
    },
    768: {
      nav: true,
      dots: true,
    },
  },
};
const carouselPropsActions = {
  nav: true,
  dots: false,
  margin: 30,
  items: 3,
  navText: [
    "<svg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5 1C4.68 1.32 2.2 3.8 1 5L5 9' stroke='#828282' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></svg>",
    "<svg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1 1C1.32 1.32 3.8 3.8 5 5L1 9' stroke='#828282' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></svg>",
  ],
  responsive: {
    992: {
      items: 2,
    },
    1200: {
      items: 3,
    },
  },
};

interface Props {
  type: string;
}

const Carousel: React.FunctionComponent<Props> = ({ type }) => {
  switch (type) {
    case 'actions': {
      return (
        <OwlCarousel
          className="actions-slider owl-theme"
          {...carouselPropsActions}
        >
          <div className="actions-slider-item">
            <div className="actions-slider-item__icon" data-count="1000">
              <img src={acsl1} alt="#" />
            </div>
            <div className="actions-slider-item__info">
              <span className="actions-slider-item__title">Резюме</span>
              <span className="actions-slider-item__text">
                Получить новый пакет резюме.
                <br />
                Доступно 1000 шт.
              </span>
              <div className="actions-slider-item__button-wrap">
                <StyledLink
                  text="Получить"
                  path="/"
                  inlineStyles={{
                    padding: '10px',
                  }}
                ></StyledLink>
              </div>
            </div>
          </div>
          <div className="actions-slider-item">
            <div className="actions-slider-item__icon" data-count="420">
              <img src={acsl2} alt="#" />
            </div>
            <div className="actions-slider-item__info">
              <span className="actions-slider-item__title">Кандидаты</span>
              <span className="actions-slider-item__text">
                Провести процесс привлечения кандидатов.
                <br />
                Доступно 420 шт.
              </span>
              <div className="actions-slider-item__button-wrap">
                <StyledLink
                  text="Получить"
                  path="/"
                  inlineStyles={{
                    padding: '10px',
                  }}
                ></StyledLink>
              </div>
            </div>
          </div>
          <div className="actions-slider-item">
            <div className="actions-slider-item__icon" data-count="2">
              <img src={acsl3} alt="#" />
            </div>
            <div className="actions-slider-item__info">
              <span className="actions-slider-item__title">Тестирование</span>
              <span className="actions-slider-item__text">
                Тестирование кандидата на псих. и проф. критерии.
                <br />
                Доступно 2 шт.
              </span>
              <div className="actions-slider-item__button-wrap">
                <StyledLink
                  text="Получить"
                  path="/"
                  inlineStyles={{
                    padding: '10px',
                  }}
                ></StyledLink>
              </div>
            </div>
          </div>
          <div className="actions-slider-item">
            <div className="actions-slider-item__icon" data-count="12">
              <img src={acsl4} alt="#" />
            </div>
            <div className="actions-slider-item__info">
              <span className="actions-slider-item__title">Видео-интервью</span>
              <span className="actions-slider-item__text">
                Автоматическое тестирование человека.
                <br />
                Доступно 12 шт.
              </span>
              <div className="actions-slider-item__button-wrap">
                <StyledLink
                  text="Получить"
                  path="/"
                  inlineStyles={{
                    padding: '10px',
                  }}
                ></StyledLink>
              </div>
            </div>
          </div>
        </OwlCarousel>
      );
    }
    case 'important': {
      return (
        <OwlCarousel
          className="owl-theme important-slider owl-carousel"
          {...carouselPropsImportant}
        >
          <a
            className="important-slider-item important-slider-item_1"
            href="/"
            style={style}
          >
            <span className="important-slider-item__info">
              <span className="important-slider-item__count">+14</span>
              <span className="important-slider-item__title">
                Приступили к тестовому заданию
              </span>
            </span>
          </a>
          <a
            className="important-slider-item important-slider-item_1"
            href="/"
            style={style}
          >
            <span className="important-slider-item__info">
              <span className="important-slider-item__count">+14</span>
              <span className="important-slider-item__title">
                Приступили к тестовому заданию
              </span>
            </span>
          </a>
          <a
            className="important-slider-item important-slider-item_1"
            href="/"
            style={style}
          >
            <span className="important-slider-item__info">
              <span className="important-slider-item__count">+14</span>
              <span className="important-slider-item__title">
                Приступили к тестовому заданию
              </span>
            </span>
          </a>
          <a
            className="important-slider-item important-slider-item_1"
            href="/"
            style={style}
          >
            <span className="important-slider-item__info">
              <span className="important-slider-item__count">+14</span>
              <span className="important-slider-item__title">
                Приступили к тестовому заданию
              </span>
            </span>
          </a>
          <a
            className="important-slider-item important-slider-item_1"
            href="/"
            style={style}
          >
            <span className="important-slider-item__info">
              <span className="important-slider-item__count">+14</span>
              <span className="important-slider-item__title">
                Приступили к тестовому заданию
              </span>
            </span>
          </a>
        </OwlCarousel>
      );
    }
    default: {
      return <div>123</div>;
    }
  }
};

export default Carousel;
