import 'flatpickr/dist/themes/light.css';
import './calendar.scss';
import React, { useState } from 'react';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
// eslint-disable-next-line import/extensions
import { Russian } from 'flatpickr/dist/l10n/ru.js';
import { BaseOptions } from 'flatpickr/dist/types/options';
import { DateTimeFormats } from '../../../helpers/formatEnum';
import { StatDates } from '../../../store/actions/vacancies';
import StyledLink from '../StyledComponents/StyledLink';

interface CalendarInterface {
  handleShowDateSelector: {
    show: () => void;
    close: () => void;
    saveAndClose: (dates: StatDates) => void;
  };
  options: Partial<BaseOptions>;
}

const Calendar: React.FC<CalendarInterface> = ({
  handleShowDateSelector,
  options,
}) => {
  const [dates, setDates] = useState({
    dateFrom: moment()
      .startOf('month')
      .format(DateTimeFormats.DATE_FORMAT_API),
    dateTo: moment()
      .endOf('month')
      .format(DateTimeFormats.DATE_FORMAT_API),
  });
  return (
    <>
      <form className="calendar__form" action="#" />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className="calendar__title" htmlFor="date">
        Дата статистики
      </label>
      <Flatpickr
        className="custom-calendar"
        options={{
          locale: Russian,
          dateFormat: 'd.m.Y',
          disableMobile: true,
          ...options,
        }}
        onChange={e => {
          if (e.length === 2) {
            setDates({
              dateFrom: moment(e[0]).format(DateTimeFormats.DATE_FORMAT_API),
              dateTo: moment(e[1]).format(DateTimeFormats.DATE_FORMAT_API),
            });
          }
        }}
      />

      <StyledLink
        type="submit"
        button
        text="Изменить даты"
        handleСlick={() => handleShowDateSelector.saveAndClose(dates)}
        inlineStyles={{
          height: '41px',
        }}
      />
    </>
  );
};
export default Calendar;
