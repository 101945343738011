import React from 'react';
import styled from 'styled-components';

const StyledVacancyDisplayList = styled.ul<{
  modificator: boolean | undefined;
}>`
  margin: 0;
  padding: 0;
  list-style-type: none;
  max-height: ${props => (props.modificator ? '342px' : 'auto')};
  padding: ${props => (props.modificator ? '2px 20px 2px 2px' : '0')};
  max-height: ${props => (props.modificator ? '490px' : '542px')};
  overflow-y: scroll;
  margin-bottom: ${props => (props.modificator ? '20px' : '0')};
`;

interface PropsInterface {
  modifiedItem: boolean;
  id?: string;
}

const VacancyDisplayList: React.FC<PropsInterface> = ({
  modifiedItem,
  children,
  id,
}) => {
  return (
    <StyledVacancyDisplayList modificator={modifiedItem} id={id}>
      {children}
    </StyledVacancyDisplayList>
  );
};

export default VacancyDisplayList;
