import React, { useState } from 'react';
import styled from 'styled-components';
import VacancyDisplayContent from './VacancyDisplayContent';
import useTypedSelector from '../../../hooks/useTypedSelector';

const StyledVacancyDisplay = styled.section`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 105;
  display: flex;
  box-shadow: 0px 4px 20px rgba(56, 56, 81, 0.17);
  background: #ffffff;
  opacity: 1;
  transition: all 0.3s ease;

  &.active {
    .fixed-nav__button {
      svg {
        transform: rotateZ(180deg);
      }
    }

    .fixed-nav__content {
      display: block;
    }
  }

  @media (max-width: 991px) {
    display: none;
  }
`;

const StyledBar = styled.div`
  width: 56px;
  padding-top: 58px;
`;

const StyledBarButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 56px;

  svg {
    max-width: 22px;
  }
`;

const StyledBarElement = styled.div`
  position: relative;
  height: 190px;
  border-bottom: 1px solid #e0e0e0;

  span {
    position: absolute;
    left: 50%;
    bottom: 30px;
    transform-origin: 0 0;
    transform: rotateZ(-90deg) translateX(-29px) translateY(-50%);
    display: block;
    font-size: 17px;
    line-height: 29px;
    color: #828282;
  }

  &:before {
    content: attr(data-count);
    position: absolute;
    left: 50%;
    top: 20px;
    transform: translateX(-50%);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    font-size: 17px;
    font-weight: 700;
    line-height: 29px;
    color: #ffffff;
    background: #ff7557;
  }
`;

const StyledBarContent = styled.div`
  display: none;
  max-width: 325px;
  padding: 58px 30px 25px 15px;
  overflow-y: auto;
`;

const VacancyDisplay: React.FunctionComponent = () => {
  const [openedNav, setOpenedNav] = useState(false);

  const handleOpenNav = () => {
    setOpenedNav(!openedNav);
  };
  const { totalResult } = useTypedSelector(r => r.mainData.vacanciesNav);
  return (
    <StyledVacancyDisplay className={openedNav ? 'active' : ''}>
      <StyledBar>
        <StyledBarButton
          className="fixed-nav__button"
          type="button"
          onClick={handleOpenNav}
        >
          <svg
            width="22"
            height="16"
            viewBox="0 0 22 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.8145 0.240629C14.5148 -0.0802096 14.016 -0.0802096 13.7058 0.240629C13.4061 0.550628 13.4061 1.06657 13.7058 1.37585L19.3277 7.19068H0.776183C0.343728 7.1914 0 7.54693 0 7.99422C0 8.44151 0.343728 8.8086 0.776183 8.8086H19.3277L13.7058 14.6126C13.4061 14.9334 13.4061 15.4501 13.7058 15.7594C14.016 16.0802 14.5155 16.0802 14.8145 15.7594L21.7674 8.56797C22.0775 8.25797 22.0775 7.74203 21.7674 7.43275L14.8145 0.240629Z"
              fill="#828282"
            />
          </svg>
        </StyledBarButton>
        <StyledBarElement data-count={totalResult || '...'}>
          <span>Вакансии</span>
        </StyledBarElement>
      </StyledBar>
      <StyledBarContent className="fixed-nav__content">
        <VacancyDisplayContent modifiedItem />
      </StyledBarContent>
    </StyledVacancyDisplay>
  );
};
export default VacancyDisplay;
