import React from 'react';
import { FallbackProps } from 'react-error-boundary';

const ErrorComponent: React.FC<FallbackProps> = ({ error }) => {
  return (
    <div role="alert" className="container">
      <p>Что-то пошло не так:</p>
      <pre>{error.message}</pre>
    </div>
  );
};
export default ErrorComponent;
