import React from 'react';
import { Vacancy } from '../../types/vacancies';
// import StyledSwitch from '../Template/StyledComponents/StyledSwitch';

interface ArchivedItem {
  vacancy: Vacancy;
  handleVacancy: (vacancy: Vacancy) => void;
}

const VacanciesCardArchive: React.FC<ArchivedItem> = ({
  vacancy,
  handleVacancy,
}) => {
  return (
    <div className="card-block">
      <div className="row first-row">
        <div className="col-lg-9 col-md-9 col-9">
          <div className="card-block__name">{vacancy.name}</div>
        </div>
        <div className="col-lg-3 col-md-3 col-3">
          <div className="custom-check">
            <div className="custom-check__title" />
            <div className="custom-control custom-switch">
              <input
                onChange={() => {
                  handleVacancy(vacancy);
                }}
                className="custom-control-input"
                id={`archiveVacancies-${vacancy.id}`}
                type="checkbox"
                checked={vacancy.status === 'Y'}
              />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label
                className="custom-control-label"
                htmlFor={`archiveVacancies-${vacancy.id}`}
              />
            </div>
          </div>
          {/* <StyledSwitch
            handleChange={() => {
              handleVacancy(vacancy);
            }}
            id={`archiveVacancies-${vacancy.id}`}
            checked={vacancy.status === 'Y'}
            inlineStyles={{ paddingLeft: '10px' }}
          /> */}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-9 col-md-9 col-9">
          <div className="archive-body__text">
            Перенесена в архив<span>12.12.2019</span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default VacanciesCardArchive;
