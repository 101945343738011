import { Dispatch } from 'redux';

import { FormikValues } from 'formik';

import axios from 'axios';
import { config, url } from './actionsAssets';

import { CompaniesActions, CompaniesActionTypes } from '../../types/companies';

import { MainPageActions, MainPageActionTypes } from '../../types/mainPage';

export const setCompanyActive = (id: string) => {
  return async (dispatch: Dispatch<MainPageActions>) => {
    try {
      const activeCompany = await axios.post(
        `${url}company/${id}/select/`,
        {},
        config,
      );
      dispatch({
        type: MainPageActionTypes.FETCH_ACTIVE_COMPANY,
        payload: activeCompany.data,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };
};
export const fetchCompanyStaff = (id: string) => {
  return async (dispatch: Dispatch<CompaniesActions>) => {
    try {
      const viewCompany = await axios.get(`${url}rule/company/${id}/`, config);
      dispatch({
        type: CompaniesActionTypes.FETCH_COMPANIES_STAFF,
        payload: viewCompany.data,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };
};
export const fetchCompaniesPage = (limit: number, page: number) => {
  return async (dispatch: Dispatch<CompaniesActions>) => {
    try {
      const params = `limit=${limit}&offset=${page}&metaInfo[design]=true`;
      const viewCompany = await axios.get(
        `${url}company/list/?${params}`,
        config,
      );
      dispatch({
        type: CompaniesActionTypes.FETCH_COMPANIES,
        payload: viewCompany.data,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };
};

export const resetStaff = () => {
  return async (dispatch: Dispatch<CompaniesActions>) => {
    dispatch({
      type: CompaniesActionTypes.RESET_COMPANY_STAFF,
    });
  };
};
export const fetchStaffId = (id: string) => {
  return async (dispatch: Dispatch<CompaniesActions>) => {
    dispatch({
      type: CompaniesActionTypes.FETCH_COMPANY_STAFF_ID,
      payload: id,
    });
  };
};
export const createCompanyStaff = (id: string, values: FormikValues) => {
  return async (dispatch: Dispatch<CompaniesActions>) => {
    try {
      const companyStaff = await axios.post(
        `${url}rule/company/${id}/add/`,
        values,
        config,
      );
      dispatch({
        type: CompaniesActionTypes.SET_COMPANY_STAFF_CHANGE,
        payload: companyStaff.data,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };
};

export const updateCompanyStaff = (
  id: string,
  values: FormikValues,
  userId: string,
) => {
  return async (dispatch: Dispatch<CompaniesActions>) => {
    try {
      const params = {
        rule_vacancy: String(values.rule_vacancy),
        rule_resume: String(values.rule_resume),
        rule_interview: String(values.rule_interview),
      };

      const updateStaff = await axios.put(
        `${url}rule/company/${id}/${userId}/`,
        params,
        config,
      );
      dispatch({
        type: CompaniesActionTypes.SET_COMPANY_STAFF_CHANGE,
        payload: updateStaff.data,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };
};
export const deleteCompanyStaff = (companyId: string, userId: string) => {
  return async (dispatch: Dispatch<CompaniesActions>) => {
    try {
      const viewCompany = await axios.delete(
        `${url}rule/company/${userId}/${companyId}/`,
        config,
      );
      dispatch({
        type: CompaniesActionTypes.SET_COMPANY_STAFF_CHANGE,
        payload: viewCompany.data,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };
};
export const addCompany = (values: FormikValues) => {
  return async (dispatch: Dispatch<CompaniesActions>) => {
    try {
      const company = await axios.post(`${url}company/add/`, values, config);
      dispatch({
        type: CompaniesActionTypes.SET_COMPANY_SUCCESS,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };
};
export const fetchCompanyEdit = (id: string) => {
  return async (dispatch: Dispatch<CompaniesActions>) => {
    try {
      const companyDate = await axios.get(`${url}company/${id}/`, config);
      dispatch({
        type: CompaniesActionTypes.FETCH_COMPANY_EDIT,
        payload: companyDate.data,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };
};
export const setCompanyEdit = (values: FormikValues) => {
  return async (dispatch: Dispatch<CompaniesActions>) => {
    try {
      const editCompany = await axios.put(
        `${url}company/${values.id}/`,
        values,
        config,
      );
      dispatch({
        type: CompaniesActionTypes.SET_COMPANY_SUCCESS,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };
};
export const resetCompanySuccess = () => {
  return async (dispatch: Dispatch<CompaniesActions>) => {
    dispatch({
      type: CompaniesActionTypes.RESET_COMPANY_SUCCESS,
    });
  };
};
export const fetchCompanyCities = () => {
  return async (dispatch: Dispatch<CompaniesActions>) => {
    try {
      const cityData = await axios.get(`${url}dictionary/cities/`, config);

      dispatch({
        type: CompaniesActionTypes.FETCH_COMPANY_CITY,
        payload: cityData.data,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };
};
