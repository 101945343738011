import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { SeekerReport, VacancySeeker } from '../../../types/vacancies';
import { wordDeclination, wordsDict } from '../../../helpers/wordDeclination';
import {
  ScheduleEvent,
  ScheduleEventNumber,
  ScheduleEventWrap,
} from '../StyledVacanciesComponents/StyledVacanciesComponents';

export interface HandleModalReport {
  close: () => void;
  show: (seeker: VacancySeeker) => void;
  closeWithSave: (newReport: SeekerReport) => void;
}

export type HandleInterViewSeeker = (
  status: 'Y' | 'N',
  interview_id: number | string,
  resume_id: number | string,
) => void;

const StyledScheduleLink = styled.button<{
  presence?: boolean;
}>`
  display: inline-block;
  width: 100%;
  padding: 2px 5px;
  border: 1px solid ${props => (props.presence ? '#27ae60' : '#FF6363')};
  border-radius: 16px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #4f4f4f;
  transition: all 0.3s ease;
  width: 48%;

  &:hover,
  &:focus,
  &.done {
    background-color: ${props => (props.presence ? '#27ae60' : '#FF6363')};
    color: #ffffff;
  }

  &.done {
    width: 130px;
  }

  @media (max-width: 767px) {
    font-size: 11px;
  }

  @media (min-width: 768px) {
    padding: 3px 5px;
  }
`;

const StyledScheduleResumeLink = styled.a`
  text-decoration: underline;
  color: #4f4f4f;

  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

interface VISeeker {
  seeker: VacancySeeker;
  iteration: number;
  handleModalReport: HandleModalReport;
  handleSetInterviewSeeker: HandleInterViewSeeker;
}

const VacancyInterviewsDayItem: React.FC<VISeeker> = ({
  seeker,
  iteration,
  handleModalReport,
  handleSetInterviewSeeker,
}) => {
  const dob = moment(seeker.year_of_birthday, 'DD.MM.YYYY');
  const years = moment().diff(dob, 'years');
  return (
    <ScheduleEvent>
      <ScheduleEventNumber>№{iteration + 1}</ScheduleEventNumber>
      <ScheduleEventWrap>
        <div className="flex-wrapper">
          <span className="name">
            {seeker.first_name} {seeker.last_name}, {years}{' '}
            {wordDeclination(years, wordsDict.YEARS_FORMS)}
          </span>
          {seeker.status === 'Y' ? (
            <button
              type="button"
              onClick={() => handleModalReport.show(seeker)}
            >
              Добавить отчет
            </button>
          ) : (
            <StyledScheduleResumeLink href="/">Резюме</StyledScheduleResumeLink>
          )}
        </div>
        <div className="flex-wrapper">
          {/* класс для выбранного пункта done */}
          {/* eslint-disable-next-line no-nested-ternary */}
          {seeker.status.length === 0 ? (
            <>
              <StyledScheduleLink
                onClick={() => {
                  handleSetInterviewSeeker(
                    'N',
                    seeker.interview_id,
                    seeker.resume_id,
                  );
                }}
              >
                Неявка
              </StyledScheduleLink>
              <StyledScheduleLink
                presence
                onClick={() => {
                  handleSetInterviewSeeker(
                    'Y',
                    seeker.interview_id,
                    seeker.resume_id,
                  );
                }}
              >
                Явка
              </StyledScheduleLink>
            </>
          ) : seeker.status === 'N' ? (
            <StyledScheduleLink disabled>Неявка</StyledScheduleLink>
          ) : (
            <StyledScheduleLink presence disabled>
              Явка
            </StyledScheduleLink>
          )}
        </div>
      </ScheduleEventWrap>
    </ScheduleEvent>
  );
};

export default VacancyInterviewsDayItem;
