import React from 'react';
import styled from 'styled-components';

const StyledVacancyDisplayTitle = styled.h3<{
  modificator: boolean | undefined;
}>`
  display: flex;
  align-items: center;
  margin: 0;
  font-weight: 700;
  font-size: 17px;
  line-height: 29px;
  color: #000000;
  margin-right: ${props => (props.modificator ? '20px' : '0')};

  .count {
    display: ${props => (props.modificator ? 'none' : 'inline-flex')};
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin-left: 10px;
    border-radius: 50%;
    font-size: 13px;
    line-height: 18px;
    color: #ffffff;
    background: #ff7557;
  }
`;

interface PropsInterface {
  modifiedItem: boolean;
  count?: number;
}

const VacancyDisplayTitle: React.FC<PropsInterface> = ({
  children,
  modifiedItem,
  count,
}) => {
  return (
    <StyledVacancyDisplayTitle modificator={modifiedItem}>
      <span>{children}</span>
      <span className="count">{count || '...'}</span>
    </StyledVacancyDisplayTitle>
  );
};

export default VacancyDisplayTitle;
