import React from 'react';
import StyledLink from '../Template/StyledComponents/StyledLink';
import StyledSwitch from '../Template/StyledComponents/StyledSwitch';
import { Vacancy } from '../../types/vacancies';

interface CardItem {
  vacancy: Vacancy;
  handleVacancy: (vacancy: Vacancy) => void;
}

const VacanciesCard: React.FC<CardItem> = ({ vacancy, handleVacancy }) => {
  return (
    <div className="card-block">
      <div className="row first-row">
        <div className="col-lg-7 col-md-9 col-9">
          <div className="card-block__name">{vacancy.name}</div>
        </div>
        <div className="col-lg-5 col-md-3 col-3">
          <div className="custom-check">
            <div className="custom-check__title">Активная</div>
            <StyledSwitch
              id={`activeVacancies-${vacancy.id}`}
              handleChange={() => {
                handleVacancy(vacancy);
              }}
              checked={vacancy.status === 'Y'}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-7">
          <div className="row card-block__row">
            <div className="col-lg-6 col-md-6 col-6">
              <div className="card-block__title">Резюме:</div>
            </div>
            <div className="col-lg-6 col-md-6 col-6">
              <div className="card-block__number">1000</div>
            </div>
          </div>
          <div className="row card-block__row">
            <div className="col-lg-6 col-md-6 col-6">
              <div className="card-block__title">Анкеты</div>
            </div>
            <div className="col-lg-6 col-md-6 col-6">
              <div className="card-block__number">300</div>
            </div>
          </div>
          <div className="row card-block__row">
            <div className="col-lg-6 col-md-6 col-6">
              <div className="card-block__title">Собеседования:</div>
            </div>
            <div className="col-lg-6 col-md-6 col-6">
              <div className="card-block__number">25</div>
            </div>
          </div>
        </div>
        <div className="col-lg-5">
          <div className="details-btns">
            <div className="details-btns__button-wrap details-btns__button-wrap--more">
              <StyledLink
                text="Подробнее"
                path={`/vacancies/${vacancy.id}`}
                inlineStyles={{
                  height: '38px',
                }}
                $whiteBackground
                $border
              ></StyledLink>
            </div>
            <div className="details-btns__button-wrap">
              <StyledLink
                text="Запустить найм"
                path="/"
                inlineStyles={{
                  height: '38px',
                }}
              ></StyledLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default VacanciesCard;
