import React, { useState, useMemo } from 'react';
import { CompanyStaff, StaticDataType } from '../../../../types/companies';
import { getRuleActions } from '../../../../helpers/viewComapny';

export interface StaffProps {
  staff: CompanyStaff;
  handleEditStaff: (value: string) => void;
  handleDeleteStaff: (value: string) => void;
  staticData: StaticDataType;
}

const ViewCompanyCard: React.FC<StaffProps> = ({
  staff,
  handleEditStaff,
  handleDeleteStaff,
  staticData,
}) => {
  const staffVacancy = useMemo(
    () => getRuleActions(staticData.ruleAction, staff.rule_vacancy),
    [staticData.ruleAction, staff.rule_interview],
  );
  const staffInterview = useMemo(
    () => getRuleActions(staticData.ruleAction, staff.rule_vacancy),
    [staticData.ruleAction, staff.rule_vacancy],
  );
  const staffType = useMemo(
    () => getRuleActions(staticData.ruleList, staff.type),
    [staticData.ruleList, staff.type],
  );
  const staffResume = useMemo(
    () => getRuleActions(staticData.ruleAction, staff.rule_resume),
    [staticData.ruleAction, staff.rule_resume],
  );

  return (
    <div className="company-table__body">
      <div className="company-table__row">
        <div className="company-table__row-item">
          {staff.name} {staff.last_name}
        </div>
        <div className="company-table__row-item">{staffType}</div>
        <div className="company-table__row-item">{staffVacancy}</div>
        <div className="company-table__row-item">{staffInterview}</div>
        <div className="company-table__row-item">{staffResume}</div>

        <div className="company-table__row-item last">
          <div className="company-table__icons">
            <button
              className="company-table__icons-pen"
              data-toggle="modal"
              data-target="#exampleModal"
              type="button"
              onClick={() => handleEditStaff(staff.user_id)}
            >
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.8147 3.17086L15.6797 7.05478L5.89624 16.8861L2.0334 13.0022L11.8147 3.17086ZM18.6125 2.23414L16.8889 0.502046C16.2227 -0.167349 15.1411 -0.167349 14.4727 0.502046L12.8216 2.16122L16.6866 6.04518L18.6125 4.10984C19.1292 3.59062 19.1292 2.75333 18.6125 2.23414ZM0.0107555 18.4614C-0.0595831 18.7795 0.226226 19.0645 0.542821 18.9872L4.84975 17.9378L0.98691 14.0539L0.0107555 18.4614Z"
                  fill="#CDCEDE"
                />
              </svg>
            </button>
            <div className="company-table__icons-delete">
              <button
                type="button"
                onClick={() => handleDeleteStaff(staff.user_id)}
              >
                <svg
                  width="14"
                  height="16"
                  viewBox="0 0 14 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.999993 14C0.999993 15.1025 1.89695 16 2.99998 16H11C12.103 16 13 15.1025 13 14V4.00001H0.999993V14Z"
                    fill="#CDCEDE"
                  />
                  <path
                    d="M9 0.999994V0H5V0.999994H0V2.99998H14V0.999994H9Z"
                    fill="#CDCEDE"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ViewCompanyCard;
