import React from 'react';
import './vacancyAutomatic.scss';
import stickyOn from '../../../assets/image/sticky-on.svg';
import iconPdf from '../../../assets/image/icon-pdf.svg';
import iconPng from '../../../assets/image/icon-png.svg';
import iconJpg from '../../../assets/image/icon-jpg.svg';
import iconDoc from '../../../assets/image/icon-doc.svg';
import iconXls from '../../../assets/image/icon-xls.svg';

const VacancyAutomatic: React.FunctionComponent = () => {
  return (
    <>
      <div className="content-system-section">
        <div className="content-system-section__navline">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <div className="sticky-block">
                  <div className="sticky-list">
                    <a
                      className="scrolling__link sticky-link active"
                      href="/automatic"
                    >
                      Автоматическое управление
                    </a>
                    <a
                      className="scrolling__link sticky-link"
                      href="/connection"
                    >
                      Подключение модулей
                    </a>
                    <a className="scrolling__link sticky-link" href="/setting">
                      Настройки процесса найма
                    </a>
                    <a
                      className="scrolling__link sticky-link"
                      href="/test-work"
                    >
                      Тестовое задание
                    </a>
                    <a className="scrolling__link sticky-link" href="/testing">
                      Тестирование навыков
                    </a>
                  </div>
                  <div className="sticky-bttn">
                    <a className="dflt-bttn" href="/">
                      Сохранить изменения
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-9">
                <div className="sticky-right" id="automatic">
                  <div className="sticky-right__title">
                    Автоматическое управление
                  </div>
                  <div className="sticky-radio">
                    <div className="dflt-radio">
                      <input type="radio" id="turn-on" name="AutoManagement" />
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label htmlFor="turn-on">
                        <span>Включено</span>
                      </label>
                    </div>
                    <div className="dflt-radio">
                      <input type="radio" id="turn-off" name="AutoManagement" />
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label htmlFor="turn-off">
                        <span>Выключено</span>
                      </label>
                    </div>
                  </div>
                  <div className="available-resumes">
                    <div className="available-resumes__title">
                      Доступно:<span>120</span>резюме
                    </div>
                    <div className="available-amount">
                      <input
                        className="available-amount__input"
                        type="number"
                        placeholder="Кол-во резюме в работу"
                      />
                      <a className="dflt-bttn" href="/">
                        Запустить
                      </a>
                    </div>
                  </div>
                  <div className="grey-block">
                    <div className="grey-block__img">
                      <img src={stickyOn} alt="#" />
                    </div>
                    <div className="grey-block__text">
                      <div className="grey-block__title">Запуск процессов</div>
                      <div className="grey-block__desc">
                        В автоматическом режиме процессы запускаются при
                        добавлении нового резюме. Если автоматический режим
                        выключен, то запуск происходит на данной странице
                      </div>
                    </div>
                  </div>
                  <a className="mobile-btn dflt-bttn" href="/#">
                    Сохранить
                  </a>
                </div>
                <div className="sticky-right connection" id="connection">
                  <div className="sticky-right__title">Подключение модулей</div>
                  <div className="connection-row first">
                    <div className="custom-control custom-checkbox">
                      <input
                        className="custom-control-input"
                        id="defaultUnchecked"
                        type="checkbox"
                      />
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label
                        className="custom-control-label"
                        htmlFor="defaultUnchecked"
                      >
                        Приглашение кандидата с отклика
                      </label>
                    </div>
                    <div className="connection-row__right">
                      <div className="connection-row__right-amount connection-row__btn">
                        <span>100</span>шт.
                      </div>
                      <a
                        className="connection-row__right-yellow connection-row__btn"
                        href="/"
                      >
                        Купить
                      </a>
                    </div>
                  </div>
                  <div className="connection-row">
                    <div className="custom-control custom-checkbox">
                      <input
                        className="custom-control-input"
                        id="connectionType-1"
                        type="checkbox"
                      />
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label
                        className="custom-control-label"
                        htmlFor="connectionType-1"
                      >
                        Приглашение кандидата с резюме
                      </label>
                    </div>
                    <div className="connection-row__right">
                      <div className="connection-row__right-amount connection-row__btn">
                        <span>100</span>шт.
                      </div>
                      <a
                        className="connection-row__right-yellow connection-row__btn"
                        href="/"
                      >
                        Купить
                      </a>
                    </div>
                  </div>
                  <div className="connection-row">
                    <div className="custom-control custom-checkbox">
                      <input
                        className="custom-control-input"
                        id="connectionType-2"
                        type="checkbox"
                      />
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label
                        className="custom-control-label"
                        htmlFor="connectionType-2"
                      >
                        Тестовое задание
                      </label>
                    </div>
                    <div className="connection-row__right">
                      <div className="connection-row__right-amount connection-row__btn">
                        <span>100</span>шт.
                      </div>
                      <a
                        className="connection-row__right-yellow connection-row__btn"
                        href="/"
                      >
                        Купить
                      </a>
                    </div>
                  </div>
                  <div className="connection-row">
                    <div className="custom-control custom-checkbox">
                      <input
                        className="custom-control-input"
                        id="connectionType-3"
                        type="checkbox"
                      />
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label
                        className="custom-control-label"
                        htmlFor="connectionType-3"
                      >
                        Психологический тест
                      </label>
                    </div>
                    <div className="connection-row__right">
                      <div className="connection-row__right-free connection-row__btn">
                        Бесплатно
                      </div>
                    </div>
                  </div>
                  <div className="connection-row">
                    <div className="custom-control custom-checkbox">
                      <input
                        className="custom-control-input"
                        id="connectionType-4"
                        type="checkbox"
                      />
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label
                        className="custom-control-label"
                        htmlFor="connectionType-4"
                      >
                        Тестирование проф. навыков
                      </label>
                    </div>
                    <div className="connection-row__right">
                      <div className="connection-row__right-amount connection-row__btn">
                        <span>100</span>шт.
                      </div>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a
                        className="connection-row__right-yellow connection-row__btn"
                        href="#"
                      >
                        Купить
                      </a>
                    </div>
                  </div>
                  <div className="connection-row last">
                    <div className="custom-control custom-checkbox">
                      <input
                        className="custom-control-input"
                        id="connectionType-5"
                        type="checkbox"
                      />
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label
                        className="custom-control-label"
                        htmlFor="connectionType-5"
                      >
                        Запись на собеседование
                      </label>
                    </div>
                    <div className="connection-row__right">
                      <div className="connection-row__right-free connection-row__btn">
                        Бесплатно
                      </div>
                    </div>
                  </div>
                  <div className="grey-block">
                    <div className="grey-block__img">
                      <img src="image/flag.svg" alt="#" />
                    </div>
                    <div className="grey-block__text">
                      <div className="grey-block__desc">
                        Ознакомьтесь с возможностями каждого модуля на{' '}
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a className="grey-block__link" href="#">
                          данной странице
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a className="mobile-btn dflt-bttn" href="#">
                    Сохранить
                  </a>
                </div>
                <div className="sticky-right setting" id="setting">
                  <div className="sticky-right__title">
                    Настройки процесса найма
                  </div>
                  <div className="setting-desc">
                    Здесь отображаются промежуточные шаги вашего найма. Вы
                    можете поменять их последовательность, добавить или убрать
                    шаг.
                  </div>
                  <div className="setting-row">
                    <div className="setting-col">
                      <div className="drag-drop" id="drag-drop">
                        <div className="drag-drop__text">
                          <p>Перетащите поочередно этапы отбора</p>
                        </div>
                        <div className="drag-drop__elem-all">
                          <div className="drag-drop__elem-1">Шаг №1</div>
                          <div className="drag-drop__elem-2">
                            <div className="drag-drop__elem">
                              Психологический тест
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="setting-col">
                      <div className="drag-drop__elem">
                        Тестирование проф. навыков
                      </div>
                      <div className="drag-drop__elem">Подключение модулей</div>
                    </div>
                  </div>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a className="mobile-btn dflt-bttn" href="#">
                    Сохранить
                  </a>
                </div>
                <div className="sticky-right" id="test-work">
                  <div className="sticky-right__title">Тестовое задание</div>
                  <div className="test-work">
                    <div className="test-work__col-1">
                      <div className="test-work__block">
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label className="test-work__label" htmlFor="task-name">
                          Название задачи
                        </label>
                        <input
                          className="test-work__input"
                          id="task-name"
                          type="text"
                          name="task-name"
                          placeholder="Написать статью"
                        />
                      </div>
                      <div className="test-work__block">
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label className="test-work__label" htmlFor="task-name">
                          Время выполнения
                        </label>
                        <input
                          className="test-work__input"
                          id="task-name"
                          type="text"
                          name="task-name"
                          placeholder="2 часа"
                        />
                      </div>
                      <div className="test-work__block">
                        <div className="test-work__label">
                          Загрузка приложений
                        </div>
                        <a className="test-btn dflt-bttn" href="/">
                          + Добавить файл
                        </a>
                      </div>
                      <div className="uppload-block" id="uppload-block">
                        <div className="uppload-block__text">
                          Или перетащите файл сюда
                        </div>
                        <div className="uppload-block__links">
                          <a className="uppload-block__link" href="/">
                            <img src={iconPdf} alt="#" />
                            Пример статьи. Тестовое. pdf
                          </a>
                          <a className="uppload-block__link" href="/">
                            <img src={iconPng} alt="#" />
                            Пример картинки. Тестовое. png
                          </a>
                          <a className="uppload-block__link" href="/">
                            <img src={iconJpg} alt="#" />
                            Пример картинки. Тестовое. jpg
                          </a>
                          <a className="uppload-block__link" href="/">
                            <img src={iconXls} alt="#" />
                            Сроки сдачи статей. exsl
                          </a>
                          <a className="uppload-block__link" href="/">
                            <img src={iconDoc} alt="#" />
                            Пример статьи. Тестовое. doc
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="test-work__col-2">
                      <div className="test-work__block">
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label
                          className="test-work__label"
                          htmlFor="task-details"
                        >
                          Название задачи
                        </label>
                        <textarea
                          className="test-work__textarea"
                          id="task-details"
                          name="task-details"
                          placeholder="Расскажите про основные аспекты задачи"
                        />
                      </div>
                      <div className="test-work__block">
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label
                          className="test-work__label"
                          htmlFor="task-details-2"
                        >
                          Суть задачи
                        </label>
                        <textarea
                          className="test-work__textarea"
                          id="task-details-2"
                          name="task-details-2"
                          placeholder="Как должен выглядеть результат?"
                        />
                      </div>
                      <div className="test-work__block">
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label
                          className="test-work__label"
                          htmlFor="task-details-3"
                        >
                          Требования к задаче
                        </label>
                        <textarea
                          className="test-work__textarea"
                          id="task-details-3"
                          name="task-details-3"
                          placeholder="Что обязательно должно присутствовать в работе?"
                        />
                      </div>
                    </div>
                    <a className="mobile-btn dflt-bttn" href="/">
                      Сохранить
                    </a>
                  </div>
                </div>
                <div className="sticky-right testing" id="testing">
                  <div className="sticky-right__title">
                    Тестирование навыков
                  </div>
                  <div className="test-work">
                    <div className="test-work__col-1">
                      <div className="test-work__block">
                        <div className="test-work__label">
                          Выберите тест из списка
                        </div>
                        <div className="dflt-select">
                          <a className="dflt-select__bttn" href="/">
                            Options
                          </a>
                          <ul className="dflt-select__dropdown">
                            <li>
                              <a href="/">Options</a>
                            </li>
                            <li>
                              <a href="/">Options</a>
                            </li>
                            <li>
                              <a href="/">Options</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <a className="mobile-btn dflt-bttn" href="/">
                    Сохранить
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default VacancyAutomatic;
