import { Dispatch } from 'redux';
import axios from 'axios';
import { MainPageActions, MainPageActionTypes } from '../../types/mainPage';
import { config, url } from './actionsAssets';

export const fetchCompanies = () => {
  return async (dispatch: Dispatch<MainPageActions>) => {
    try {
      const params = '?metaInfo[design]=true';
      const active = await axios.get(`${url}company/active/`, config);
      const selector = await axios.get(`${url}company/list/${params}`, config);
      dispatch({
        type: MainPageActionTypes.FETCH_ACTIVE_COMPANY,
        payload: active.data,
      });
      dispatch({
        type: MainPageActionTypes.FETCH_COMPANIES_HEAD,
        payload: selector.data,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };
};

export const setActiveCompany = (company: string) => {
  return async (dispatch: Dispatch<MainPageActions>) => {
    try {
      const activeCompany = await axios.post(
        `${url}company/${company}/select/`,
        '',
        config,
      );
      dispatch({
        type: MainPageActionTypes.SET_ACTIVE_COMPANY,
        payload: activeCompany.data,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };
};

export const clearVacancies = () => {
  return (dispatch: Dispatch<MainPageActions>) => {
    dispatch({ type: MainPageActionTypes.CLEAR_VACANCIES_LIST_MAIN });
  };
};

export const fetchVacanciesMain = (
  limit: number,
  vacNotifications?: boolean,
  fixed = false,
) => {
  return async (dispatch: Dispatch<MainPageActions>) => {
    try {
      const param2 = vacNotifications ? '&status=N' : '';
      const param1 = `limit=${limit}`;
      const vacancies = await axios.get(
        `${url}vacancy/list/?${param1}${param2}`,
        config,
      );
      if (fixed) {
        dispatch({
          type: MainPageActionTypes.FETCH_VACANCIES_LIST_NAV,
          payload: vacancies.data,
        });
      } else {
        dispatch({
          type: MainPageActionTypes.FETCH_VACANCIES_LIST_MAIN,
          payload: vacancies.data,
        });
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };
};

export const fetchDicts = () => {
  return async (dispatch: Dispatch<MainPageActions>) => {
    try {
      const dicts = await axios.get(`${url}dictionary/list/`, config);
      dispatch({
        type: MainPageActionTypes.FETCH_DICTS,
        payload: dicts.data,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };
};
