export interface Vacancy {
  id: string;
  name: string;
  company_id: string;
  status: 'Y' | 'N';
  url: string;
  currency_code: Currency;
  salary_from: number;
  salary_to: number;
  type_location: number;
  type_schedule: number;
  type_frequency: number;
  text_conditions: string;
  text_responsibilities: string;
  text_requirements: string;
}

export enum Currency {
  RUB = 'RUB',
  USD = 'USD',
  EUR = 'EUR',
}

export interface NewVacancy {
  status: 'Y' | 'N';
  name: string;
  url: string;
  currency_code: Currency;
  salary_from: number;
  salary_to: number;
  type_location: number;
  type_schedule: number;
  type_frequency: number;
  text_conditions: string;
  text_responsibilities: string;
  text_requirements: string;
}

export interface CVS {
  company_id: string;
  first_name: string;
  last_name: string;
  year_of_birthday: string;
  date_create: string;
  file_id: string;
  id: string;
  last_update: string;
  seeker_id: string;
  ualias_0?: string;
  url: string;
  vacancy_id: string;
}

export interface CVI {
  vid?: string | number; // for iteration
  id: string;
  vacancy_id: string;
  first_name: string;
  last_name: string;
  url: string;
  year_of_birthday?: string;
  phone: string;
  file?: string;
}

export interface VacancyInterview {
  id: string;
  name: string;
  max_place: string;
  date?: number | string;
  seekers: VacancySeeker[];
}

export interface VacancyInterviewUnixTime {
  id: string;
  name: string;
  last_name: string;
  date: number;
  vacancy_id: string;
  max_place: string;
  seekers: VacancySeeker[];
  responsible_user: string;
}

export interface VacancySeeker {
  vacancy_id: string;
  status: string;
  interview_id: number;
  resume_id: number;
  seeker_id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  year_of_birthday: string;
}

export interface VacancyStatistic {
  resume_load: number | string;
  contact_start: number;
  contact_finish: number;
  test_finish: number;
  task_finish: number;
  interview_finish: number;
}

export interface InterviewState {
  id: string;
  vacancy_id: string;
  date: string;
  responsible_user: string;
  max_place: number;
  seekers: CVS[];
}

export interface SeekerReport {
  ID: number;
  TEXT: string;
  MARK_1: number;
  MARK_2: number;
  MARK_3: number;
  MARK_4: number;
  INTERVIEW_ID: number;
  RESUME_ID: number;
}

export interface DateTimeInterface {
  date: string;
  time: string;
}

export enum VacanciesActionTypes {
  FETCH_SINGLE_VACANCY = 'FETCH_SINGLE_VACANCY',
  FETCH_SINGLE_VACANCY_STATISTIC = 'FETCH_SINGLE_VACANCY_STATISTIC',
  PUT_SINGLE_VACANCY = 'PUT_SINGLE_VACANCY',
  FETCH_LIST_VACANCIES = 'FETCH_LIST_VACANCIES',
  FETCH_LIST_VACANCIES_ARCHIVE = 'FETCH_LIST_VACANCIES_ARCHIVE',
  ERROR_FETCH_SINGLE_VACANCY = 'ERROR_FETCH_SINGLE_VACANCY',
  CLEAR_SINGLE_VACANCY = 'CLEAR_SINGLE_VACANCY',
  FETCH_SINGLE_VACANCY_INTERVIEWS_TODAY = 'FETCH_SINGLE_VACANCY_INTERVIEWS_TODAY',
  FETCH_SINGLE_VACANCY_INTERVIEWS = 'FETCH_SINGLE_VACANCY_INTERVIEWS',
  FETCH_SINGLE_VACANCY_CVS = 'FETCH_SINGLE_VACANCY_CVS',
  PUT_SINGLE_VACANCY_CV = 'FETCH_SINGLE_VACANCY_CV',
  PUT_SINGLE_VACANCY_INTERVIEW = 'PUT_SINGLE_VACANCY_INTERVIEW',
  PATCH_SINGLE_VACANCY_INTERVIEW = 'PATCH_SINGLE_VACANCY_INTERVIEW',
}

interface FetchSingleVacancy {
  type: VacanciesActionTypes.FETCH_SINGLE_VACANCY;
  payload: {
    result: Vacancy;
  };
}

interface FetchSingleVacancyStatistic {
  type: VacanciesActionTypes.FETCH_SINGLE_VACANCY_STATISTIC;
  payload: {
    result: VacancyStatistic;
  };
}

interface PutSingleVacancyCV {
  type: VacanciesActionTypes.PUT_SINGLE_VACANCY_CV;
  payload: {
    result: CVI;
  };
}

interface PutSingleVacancyInterview {
  type: VacanciesActionTypes.PUT_SINGLE_VACANCY_INTERVIEW;
  payload: {
    result: InterviewState;
  };
}

interface PutSingleVacancy {
  type: VacanciesActionTypes.PUT_SINGLE_VACANCY;
  payload: {
    result: Vacancy;
  };
}

interface FetchSingleVacancyCVS {
  type: VacanciesActionTypes.FETCH_SINGLE_VACANCY_CVS;
  payload: {
    result: CVS;
  };
}

interface FetchListVacancies {
  type: VacanciesActionTypes.FETCH_LIST_VACANCIES;
  payload: {
    result: Vacancy[];
  };
}

interface FetchListVacanciesArchive {
  type: VacanciesActionTypes.FETCH_LIST_VACANCIES_ARCHIVE;
  payload: {
    result: Vacancy[];
  };
}

interface FetchSingleVacancyInterViews {
  type: VacanciesActionTypes.FETCH_SINGLE_VACANCY_INTERVIEWS;
  payload: {
    result: VacancyInterview[];
  };
}

interface FetchSingleVacancyInterViewsToday {
  type: VacanciesActionTypes.FETCH_SINGLE_VACANCY_INTERVIEWS_TODAY;
  payload: {
    result: VacancyInterview[];
  };
}

interface ClearSingleVacancy {
  type: VacanciesActionTypes.CLEAR_SINGLE_VACANCY;
}

interface ErrorFetchSingleVacancy {
  type: VacanciesActionTypes.ERROR_FETCH_SINGLE_VACANCY;
}

export type VacanciesActions =
  | FetchSingleVacancy
  | PutSingleVacancy
  | FetchSingleVacancyCVS
  | FetchListVacancies
  | FetchListVacanciesArchive
  | ClearSingleVacancy
  | FetchSingleVacancyInterViews
  | FetchSingleVacancyInterViewsToday
  | PutSingleVacancyInterview
  | PutSingleVacancyCV
  | FetchSingleVacancyStatistic
  | ErrorFetchSingleVacancy;
