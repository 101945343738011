import React from 'react';
import styled from 'styled-components';

const StyledItem = styled.a`
  display: flex;
  margin-top: 18px;
  font-weight: normal;
  font-size: 13px;
  line-height: 21px;
  text-decoration-line: underline;
  color: #4f4f4f;

  span {
    width: 13px;
    height: 10px;
    margin-right: 16px;
  }
`;

interface PropsInterface {
  href: string;
  image: string;
  text: string;
}

const MobileItem: React.FunctionComponent<PropsInterface> = ({
  href,
  image,
  text,
}) => {
  return (
    <StyledItem href={href}>
      <span>
        <img src={image} alt="#" />
      </span>
      {text}
    </StyledItem>
  );
};

export default MobileItem;
