import React from 'react';
import { useParams } from 'react-router-dom';

interface Params {
  id: string;
}

const CatalogItem: React.FunctionComponent = () => {
  const params: Params = useParams();
  return <>Catalog item {params.id}</>;
};
export default CatalogItem;
