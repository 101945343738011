import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './vacancies.scss';
import VacanciesList from './VacanciesList';
import { useActions } from '../../hooks/useActions';
import useTypedSelector from '../../hooks/useTypedSelector';

const Vacancies: React.FunctionComponent = () => {
  const { fetchListVacancies } = useActions();
  const [page, setPage] = useState(0);
  const [archPage, setArchPage] = useState(0);

  const refetchAll = () => {
    const rfa = async () => {
      await fetchListVacancies(page, 6, false);
      await fetchListVacancies(archPage, 6, true);
    };

    rfa();
  };
  useEffect(() => {
    fetchListVacancies(page, 6, false);
  }, [page]);
  useEffect(() => {
    fetchListVacancies(archPage, 6, true);
  }, [archPage]);
  return (
    <>
      <section>
        <VacanciesList
          refetchAll={refetchAll}
          setPage={setPage}
          page={page}
          archPage={archPage}
          setArchPage={setArchPage}
        />
      </section>
    </>
  );
};

export default Vacancies;
