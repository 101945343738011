import React, { useEffect } from 'react';
import './header.scss';
import { Link, NavLink } from 'react-router-dom';
import mainLogo from '../../../assets/image/main-logo.svg';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { useActions } from '../../../hooks/useActions';
import CompaniesSelector from './CompaniesSelector/CompaniesSelector';
import MobileMenu from './MobileMenu/MobileMenu';

const Header: React.FunctionComponent = () => {
  const { fetchCompanies, fetchDicts } = useActions();
  useEffect(() => {
    fetchCompanies();
    // fetchVacanciesMain();
    // fetchVacanciesMain();
    fetchDicts();
  }, []);
  return (
    <>
      <header className="header">
        <div className="header__topline">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-2 col-lg-2 col-md-6 col-9 d-flex align-items-center d-lg-block">
                <button
                  className="main-menu-show d-lg-none hamburger hamburger--spin"
                  type="button"
                >
                  <span className="hamburger-box">
                    <span className="hamburger-inner" />
                  </span>
                </button>
                <Link className="main-logo" to="/">
                  <img src={mainLogo} alt="#" />
                </Link>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-5 d-none d-lg-block">
                <div className="help-contact">
                  <span>У вас есть вопросы?</span>
                  <span className="help-contact__dash" />
                  <a href="mailto:info@mebot24-hr.ru">info@mebot24-hr.ru</a>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-3">
                <CompaniesSelector mobile={false} />
              </div>
              <div className="col-xl-2 col-lg-2 col-md-6 col-3">
                <div className="profile-display">
                  <Link
                    className="profile-display__bttn"
                    to="/"
                    data-letters="ИН"
                  >
                    <span>Иванов Н.П.</span>
                  </Link>
                  <div className="profile-block">
                    <div className="profile-block__title">Иванов Н.П.</div>
                    <div className="profile-block__btns">
                      <Link
                        className="btn-correct profile__btn-transparent"
                        to="/profile/"
                      >
                        Редактировать профиль
                      </Link>
                      <Link
                        className="btn-out profile__btn-transparent"
                        to="/logout"
                      >
                        Выйти
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header__navline d-none d-lg-block">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-8">
                <nav className="main-nav">
                  <ul className="main-menu">
                    <li className="main-menu__item">
                      <NavLink className="main-menu__link" to="/">
                        Главная
                      </NavLink>
                    </li>
                    <li className="main-menu__item main-menu__item-vacancies">
                      <NavLink
                        className="main-menu__link"
                        to="/vacancies/"
                        activeClassName="active"
                      >
                        Вакансии
                      </NavLink>
                    </li>
                    <li className="main-menu__item">
                      <NavLink
                        className="main-menu__link"
                        to="/companies/"
                        activeClassName="active"
                      >
                        Компании
                      </NavLink>
                    </li>
                    <li className="main-menu__item">
                      <NavLink
                        className="main-menu__link"
                        to="/about/"
                        activeClassName="active"
                      >
                        О системе
                      </NavLink>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="col-lg-4">
                <Link className="other-products" to="/">
                  Другие продукты
                  <svg
                    width="6"
                    height="10"
                    viewBox="0 0 6 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1C1.32 1.32 3.8 3.8 5 5L1 9"
                      stroke="#333333"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <MobileMenu />
      </header>
    </>
  );
};
export default Header;
