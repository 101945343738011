import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Currency, NewVacancy } from '../../../types/vacancies';
import useTypedSelector from '../../../hooks/useTypedSelector';
import { Company } from '../../../types/mainPage';
import { addNewVacancy } from '../../../store/actions/vacancies';

const AddNewVacancy: React.FunctionComponent = () => {
  const { companies } = useTypedSelector(r => r.mainData.companies);
  const { dictionaries } = useTypedSelector(r => r.mainData);
  const history = useHistory();
  const [vacancy, setVacancy] = useState<NewVacancy>({
    status: 'Y',
    name: 'Новая вакансия',
    url: '',
    currency_code: Currency.RUB,
    salary_from: 0,
    salary_to: 0,
    type_location: 1,
    type_schedule: 1,
    type_frequency: 1,
    text_conditions: '',
    text_responsibilities: '',
    text_requirements: '',
  });

  const handleChangeVacancyState = (value: string | number, type: string) => {
    setVacancy(prev => {
      return { ...prev, [type]: value };
    });
  };

  const handleSaveNewVacancy = () => {
    addNewVacancy(vacancy)
      .then(r => {
        const { id } = r.data;
        history.push(`/vacancies/${id}`);
      })
      .catch(e => {
        console.log(e);
      });
  };

  return (
    <div className="content-system-section__content noVacancy-page">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <form className="vacancy-info-form" action="#">
              <div className="row">
                <div className="col-lg-6">
                  <h2
                    className="content-system-section__title novacancy__title"
                    id="create-vacancy"
                  >
                    Создание вакансии
                  </h2>
                  <div className="vacancy-info-form__row">
                    <div className="input-title">
                      <span>{vacancy.name}</span>
                    </div>
                    <input
                      className="dflt-input"
                      type="text"
                      name="name"
                      placeholder="Название вакансии"
                      value={vacancy.name}
                      onChange={e => {
                        handleChangeVacancyState(e.target.value, e.target.name);
                      }}
                    />
                  </div>
                  <div className="vacancy-info-form__row">
                    <div className="input-title">
                      <span>Компания</span>
                    </div>
                    <div className="row">
                      <div className="col-xl-12 col-sm-12 col-12">
                        <div className="form-select">
                          <select name="company">
                            {companies.map((r: Company) => (
                              <option value={r.id} key={r.id}>
                                {r.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="vacancy-info-form__row">
                    <div className="input-title">
                      <span>Заработная плата</span>
                    </div>
                    <div className="row">
                      <div className="col-xl-5 col-sm-4 col-4 small-col">
                        <input
                          className="dflt-input dflt-input_numeric"
                          type="text"
                          name="salary_from"
                          placeholder="от"
                          value={vacancy.salary_from}
                          onChange={e => {
                            handleChangeVacancyState(
                              parseInt(e.target.value, 10),
                              e.target.name,
                            );
                          }}
                        />
                      </div>
                      <div className="col-xl-5 col-sm-4 col-4 small-col">
                        <input
                          className="dflt-input dflt-input_numeric"
                          type="text"
                          name="salary_to"
                          placeholder="до"
                          value={vacancy.salary_to}
                          onChange={e => {
                            handleChangeVacancyState(
                              parseInt(e.target.value, 10),
                              e.target.name,
                            );
                          }}
                        />
                      </div>
                      <div className="col-xl-2 col-sm-4 col-4 small-col">
                        <div className="form-select">
                          <select
                            onChange={e => {
                              handleChangeVacancyState(
                                e.target.value,
                                e.target.name,
                              );
                            }}
                            name="currency_code"
                          >
                            <option value={Currency.RUB}>руб</option>
                            <option value={Currency.EUR}>eur</option>
                            <option value={Currency.USD}>usd</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="vacancy-info-form__row">
                    <div className="input-title">
                      <span>Ссылка на источник размещения</span>
                    </div>
                    <textarea
                      className="dflt-textarea link-textarea"
                      name="url"
                      placeholder="Ссылка на источник размещения"
                      value={vacancy.url}
                      onChange={e => {
                        handleChangeVacancyState(e.target.value, e.target.name);
                      }}
                    />
                    <div className="vacancy-info-form__source-select">
                      <div className="vacancy-info-form__source-select-item">
                        <input
                          type="radio"
                          id="linkHH"
                          name="vacancyInfo6"
                          checked
                        />
                        <label htmlFor="linkHH">hh</label>
                      </div>
                      <div className="vacancy-info-form__source-select-item">
                        <input
                          type="radio"
                          id="linkSuperjob"
                          name="vacancyInfo6"
                        />
                        <label htmlFor="linkSuperjob">superjob</label>
                      </div>
                      <div className="vacancy-info-form__source-select-item">
                        <input
                          type="radio"
                          id="linkRabota"
                          name="vacancyInfo6"
                        />
                        <label htmlFor="linkRabota">rabota</label>
                      </div>
                      <div className="vacancy-info-form__source-select-item">
                        <input
                          type="radio"
                          id="linkOther"
                          name="vacancyInfo6"
                        />
                        <label htmlFor="linkOther">Другое</label>
                      </div>
                    </div>
                  </div>
                  <div className="vacancy-info-form__row special">
                    <div className="input-title">
                      <span>График</span>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        {dictionaries.vacancySchedule?.map(
                          (item: { id: number; title: string | null }) => {
                            return (
                              <div className="dflt-radio" key={item.id}>
                                <input
                                  type="radio"
                                  id={`schedule${item.id}`}
                                  name="type_schedule"
                                  value={item.id}
                                  checked={vacancy.type_schedule === item.id}
                                  onChange={e => {
                                    handleChangeVacancyState(
                                      parseInt(e.target.value, 10),
                                      e.target.name,
                                    );
                                  }}
                                />
                                <label htmlFor={`schedule${item.id}`}>
                                  <span>{item.title}</span>
                                </label>
                              </div>
                            );
                          },
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="vacancy-info-form__row special">
                    <div className="input-title">
                      <span>Тип вакансии</span>
                    </div>
                    <div className="row">
                      {dictionaries.vacancyLocation?.map(
                        (item: { id: number; title: string | null }) => (
                          <div className="col-lg-6" key={item.id}>
                            <div className="dflt-radio">
                              <input
                                type="radio"
                                id={`vacancytype${item.id}`}
                                value={item.id}
                                name="type_location"
                                checked={vacancy.type_location === item.id}
                                onChange={e => {
                                  handleChangeVacancyState(
                                    Number(e.target.value),
                                    e.target.name,
                                  );
                                }}
                              />
                              <label htmlFor={`vacancytype${item.id}`}>
                                <span>{item.title}</span>
                              </label>
                            </div>
                          </div>
                        ),
                      )}
                    </div>
                  </div>
                  <div className="vacancy-info-form__row special">
                    <div className="input-title">
                      <span>Тип найма</span>
                    </div>
                    <div className="row">
                      {dictionaries.vacancyFrequency?.map(
                        (item: { id: number; title: string | null }) => (
                          <div className="col-lg-6" key={item.id}>
                            <div className="dflt-radio">
                              <input
                                type="radio"
                                value={item.id}
                                id={`worktype${item.id}`}
                                checked={vacancy.type_frequency === item.id}
                                name="type_frequency"
                                onChange={e => {
                                  handleChangeVacancyState(
                                    Number(e.target.value),
                                    e.target.name,
                                  );
                                }}
                              />
                              <label htmlFor={`worktype${item.id}`}>
                                <span>{item.title}</span>
                              </label>
                            </div>
                          </div>
                        ),
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="vacancy-info-form__row">
                    <div className="input-title">
                      <span>Требования</span>
                    </div>
                    <textarea
                      className="dflt-textarea dflt-textarea__mobile"
                      name="text_requirements"
                      style={{ height: '252px' }}
                      value={vacancy.text_requirements}
                      onChange={e => {
                        handleChangeVacancyState(e.target.value, e.target.name);
                      }}
                      placeholder="Правила организации вашего коллектива, необходимые знания..."
                    />
                  </div>
                  <div className="vacancy-info-form__row">
                    <div className="input-title">
                      <span>Условия работы</span>
                    </div>
                    <textarea
                      className="dflt-textarea dflt-textarea__mobile"
                      name="text_conditions"
                      value={vacancy.text_conditions}
                      onChange={e => {
                        handleChangeVacancyState(e.target.value, e.target.name);
                      }}
                      style={{ height: '252px' }}
                      placeholder="Опишите режим работы, местонахождение компании..."
                    />
                  </div>
                  <div className="vacancy-info-form__row">
                    <div className="input-title">
                      <span>Перечислите обязанности</span>
                    </div>
                    <textarea
                      className="dflt-textarea dflt-textarea__mobile"
                      name="text_responsibilities"
                      value={vacancy.text_responsibilities}
                      style={{ height: '252px' }}
                      onChange={e => {
                        handleChangeVacancyState(e.target.value, e.target.name);
                      }}
                      placeholder="Какими навыками и знаниями должен обладать кандидат..."
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <button
                    className="dflt-bttn"
                    type="button"
                    onClick={handleSaveNewVacancy}
                  >
                    Сохранить
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddNewVacancy;
