import React, { Fragment, useEffect, useState } from 'react';
import Flatpickr from 'react-flatpickr';
import { Russian } from 'flatpickr/dist/l10n/ru';
import moment from 'moment';
import { useActions } from '../../../hooks/useActions';
import { CVS, InterviewState } from '../../../types/vacancies';
import { DateTimeFormats } from '../../../helpers/formatEnum';
import useTypedSelector from '../../../hooks/useTypedSelector';
import { setRegisterInterviewCV } from '../../../store/actions/vacancies';

const VacancyCVsInvite: React.FC<{
  item: CVS;
  handleModal: (e: boolean) => void;
}> = ({ item, handleModal }) => {
  const { fetchSvInterview } = useActions();
  const { interviews } = useTypedSelector(r => r.vacancies.vacancy.interviews);
  const [date, setDate] = useState(moment().format('YYYYMMDD'));
  const [records, setRecords] = useState([]);
  const [recordDate, setRecordDate] = useState({
    interview: '',
    date: '',
  });
  const [period, setPeriod] = useState({
    from: moment()
      .startOf('month')
      .format(DateTimeFormats.DATE_FORMAT_API),
    to: moment()
      .endOf('month')
      .format(DateTimeFormats.DATE_FORMAT_API),
  });

  useEffect(() => {
    fetchSvInterview(
      item.vacancy_id,
      {
        today: false,
      },
      period,
    );
  }, [period]);

  const handleRegisterCV = () => {
    setRegisterInterviewCV(recordDate.interview, item.id)
      .then(() => {
        handleModal(false);
      })
      .catch(e => console.log(e));
  };

  useEffect(() => {
    if (interviews[date]) {
      setRecords(interviews[date]);
    } else {
      setRecords([]);
    }
  }, [date]);

  return (
    <div className="container">
      <h3>Назначить собеседование</h3>
      <div className="row">
        <div className="col-6">
          Выберите дату:
          <Flatpickr
            onChange={(dates, currentDateString) => {
              setDate(
                moment(
                  currentDateString,
                  DateTimeFormats.DATE_FORMAT_API,
                ).format('YYYYMMDD'),
              );
            }}
            onDayCreate={(dates, currentDateString, self, d) => {
              const iter = moment(
                d.dateObj,
                DateTimeFormats.DATE_FORMAT_API,
              ).format('YYYYMMDD');
              if (interviews[iter] && moment(d.dateObj).isAfter(moment())) {
                // eslint-disable-next-line no-param-reassign
                d.className += ' tempYellow';
              }
            }}
            onMonthChange={(dates, currentDateString, d) => {
              setPeriod({
                from: moment(
                  `${d.currentMonth + 1}.${d.currentYear}`,
                  'MM.YYYY',
                )
                  .startOf('month')
                  .format(DateTimeFormats.DATE_FORMAT_API),
                to: moment(`${d.currentMonth + 1}.${d.currentYear}`, 'MM.YYYY')
                  .endOf('month')
                  .format(DateTimeFormats.DATE_FORMAT_API),
              });
            }}
            options={{
              locale: Russian,
              dateFormat: 'd.m.Y',
              mode: 'single',
              inline: true,
              defaultDate: moment().format(DateTimeFormats.DATE_FORMAT_API),
            }}
          />
        </div>
        <div className="col-6">
          Выберите время:
          <div>
            {records.map((i: InterviewState) => (
              <Fragment key={i.id}>
                {moment().unix() < Number(i.date) && (
                  <label>
                    <input
                      type="radio"
                      name="recordDate"
                      onChange={e => {
                        setRecordDate({
                          interview: i.id,
                          date: e.target.value,
                        });
                      }}
                      disabled={i.seekers.length >= Number(i.max_place)}
                      checked={
                        recordDate.date ===
                        moment
                          .unix(Number(i.date))
                          .format(DateTimeFormats.TIME_FORMAT_API)
                      }
                      value={moment
                        .unix(Number(i.date))
                        .format(DateTimeFormats.TIME_FORMAT_API)}
                    />
                    {moment
                      .unix(Number(i.date))
                      .format(DateTimeFormats.TIME_FORMAT_API)}
                  </label>
                )}
              </Fragment>
            ))}
          </div>
        </div>
      </div>
      <button type="button" onClick={handleRegisterCV}>
        Назначить собеседование
      </button>
    </div>
  );
};

export default VacancyCVsInvite;
