import React from 'react';
import styled from 'styled-components';

const StyledVacancyDisplayHead = styled.div<{
  modificator: boolean | undefined;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

interface PropsInterface {
  modifiedItem: boolean;
}

const VacancyDisplayHead: React.FC<PropsInterface> = ({
  modifiedItem,
  children,
}) => {
  return (
    <StyledVacancyDisplayHead modificator={modifiedItem}>
      {children}
    </StyledVacancyDisplayHead>
  );
};

export default VacancyDisplayHead;
