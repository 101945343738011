import { Vacancy } from './vacancies';

export interface Company {
  name: string;
  id: string;
  color: string;
}

export interface ShortVacanciesItemInterface {
  vacancy: Vacancy;
}

export interface DictionariesI {
  companyColors: {
    id: number;
    color: string;
  }[];
  companyImages: number[];
  ruleList: {
    id: number;
    title: string;
  }[];
  ruleAction: {
    id: number;
    title: string;
  }[];
  ruleModule: {
    code: string;
    title: string;
  }[];
  vacancyLocation: {
    id: number;
    title: string;
  }[];
  vacancySchedule: {
    id: number;
    title: string;
  }[];
  vacancyFrequency: {
    id: number;
    title: string;
  }[];
}

export enum MainPageActionTypes {
  FETCH_COMPANIES_HEAD = 'FETCH_COMPANIES_HEAD',
  FETCH_ACTIVE_COMPANY = 'FETCH_ACTIVE_COMPANY',
  FETCH_DICTS = 'FETCH_DICTS',
  SET_ACTIVE_COMPANY = 'SET_ACTIVE_COMPANY',
  FETCH_VACANCIES_LIST_MAIN = 'FETCH_VACANCIES_LIST_MAIN',
  CLEAR_VACANCIES_LIST_MAIN = 'CLEAR_VACANCIES_LIST_MAIN',
  FETCH_VACANCIES_LIST_NAV = 'FETCH_VACANCIES_LIST_NAV',
}

interface FetchActiveCompanyAction {
  type: MainPageActionTypes.FETCH_ACTIVE_COMPANY;
  payload: {
    result: string;
  };
}

interface FetchDictionaries {
  type: MainPageActionTypes.FETCH_DICTS;
  payload: {
    result: DictionariesI;
  };
}

interface SetActiveCompanyAction {
  type: MainPageActionTypes.SET_ACTIVE_COMPANY;
  payload: {
    result: string;
  };
}

interface FetchVacanciesListAction {
  type: MainPageActionTypes.FETCH_VACANCIES_LIST_MAIN;
  payload: {
    result: Vacancy[];
  };
}

interface FetchVacanciesFixedNavAction {
  type: MainPageActionTypes.FETCH_VACANCIES_LIST_NAV;
  payload: {
    result: Vacancy[];
  };
}

interface ClearVacanciesListAction {
  type: MainPageActionTypes.CLEAR_VACANCIES_LIST_MAIN;
}

interface FetchCompaniesSuccessAction {
  type: MainPageActionTypes.FETCH_COMPANIES_HEAD;
  payload: {
    result: [];
    totalResultCount: string;
  };
}

export type MainPageActions =
  | FetchCompaniesSuccessAction
  | FetchActiveCompanyAction
  | SetActiveCompanyAction
  | FetchVacanciesListAction
  | FetchDictionaries
  | ClearVacanciesListAction
  | FetchVacanciesFixedNavAction;
