import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import StyledLink from '../../Template/StyledComponents/StyledLink';
import StyledLabel from '../../Template/StyledComponents/StyledLabel';
import useTypedSelector from '../../../hooks/useTypedSelector';
import { useActions } from '../../../hooks/useActions';

import {
  StyledAddForm,
  StyledWrap,
  StyledItemWrap,
  StyledBlocks,
  StyledColorBlock,
  StyledCompanyBlocks,
  StyledCompanyBlock,
  StyledButtonWrap,
} from '../StyledFormComponents/StyledFormComponents';
import StyledInput from '../../Template/StyledComponents/StyledInput';
import image2 from '../../../assets/image/build-2.png';
import image1 from '../../../assets/image/build.png';
import image3 from '../../../assets/image/build-3.png';
import image4 from '../../../assets/image/build-4.png';
import image5 from '../../../assets/image/build-5.png';
import { SelectDiv } from '../../Template/StyledComponents/StyledSelect';
import {
  CompaniesDataType,
  CompanyCity,
  CompanyColorsType,
  CompanyTomeZone,
} from '../../../types/companies';

export interface MyFormValues {
  name: string;
  city: string;
  time_zone: string;
  address: string;
  email: string;
  phone: string;
  description: string;
  id: string;
  color: string;
  image: string;
}

const StyledEditForm = styled(StyledAddForm)`
  padding-top: 38px;
`;

const colorsData = [
  { color: 'blue', index: '1' },
  { color: 'green', index: '2' },
  { color: 'violet', index: '3' },
  { color: 'darkBlue', index: '4' },
  { color: 'darkGreen', index: '5' },
];
const companies = [
  { image: image2, index: '2' },
  { image: image1, index: '1' },
  { image: image3, index: '3' },
  { image: image4, index: '4' },
  { image: image5, index: '5' },
];

interface PropsType {
  editCompany?: boolean;
}

interface Params {
  id: string;
}

const CompanyForm: React.FunctionComponent<PropsType> = ({ editCompany }) => {
  const { setCompanyEdit, setCompanyActive, fetchCompanyCities } = useActions();
  const { setSuccess, companyCity } = useTypedSelector(r => r.companies);
  const [errImage, setErrImage] = useState<boolean>(false);

  useEffect(() => {
    fetchCompanyCities();
  }, []);
  const { dictionaries } = useTypedSelector(r => r.mainData);

  const history = useHistory();
  const { fetchCompanyEdit, resetCompanySuccess } = useActions();

  const params: Params = useParams();
  useEffect(() => {
    if (editCompany) setCompanyActive(params.id);
  }, []);
  useEffect(() => {
    if (editCompany) {
      fetchCompanyEdit(params.id);
    } else {
      resetCompanySuccess();
    }
  }, [params.id]);
  const { editCompanyDate } = useTypedSelector(r => r.companies);

  useEffect(() => {
    if (setSuccess) history.push('/companies/');
  }, [setSuccess]);
  const [colors, setColors] = useState<string>('1');

  const [images, setImages] = useState<string>();

  const bg = dictionaries.companyColors
    ?.map((n: CompanyColorsType) => {
      return String(n.id) === String(colors) ? n.color : '';
    })
    .join('');
  const { addCompany } = useActions();

  useEffect(() => {
    setErrImage(false);
  }, [images]);

  useEffect(() => {
    if (editCompanyDate) {
      if (editCompanyDate.color) setColors(String(editCompanyDate.color));
      setImages(String(editCompanyDate.image));
    }
  }, [editCompanyDate]);
  const initialValues: MyFormValues = editCompanyDate
    ? { ...editCompanyDate }
    : {
        name: '',
        city: '',
        time_zone: '',
        address: '',
        email: '',
        phone: '',
        description: '',
      };
  const phoneRegExp = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/;

  return (
    <StyledEditForm className="add-form">
      <Formik
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Вы не заполнили поле'),
          city: Yup.string().required('Вы не заполнили поле'),
          time_zone: Yup.string().required('Вы не заполнили поле'),
          address: Yup.string().required('Вы не заполнили поле'),
          email: Yup.string().required('Вы не заполнили поле'),
          phone: Yup.string()
            .nullable()
            .required('Вы не заполнили поле')
            .matches(phoneRegExp, 'Введите валидный номер телефона'),
          description: Yup.string().required('Вы не заполнили поле'),
        })}
        initialValues={initialValues}
        enableReinitialize
        onSubmit={(values, actions) => {
          if (images) {
            setErrImage(false);
            const newVal = Object.assign(values, {
              image: images,
              color: colors,
              id: editCompanyDate && editCompanyDate.id,
            });
            if (editCompanyDate) {
              setCompanyEdit(newVal);
            } else {
              addCompany(newVal);
            }
          } else {
            setErrImage(true);
          }
        }}
      >
        {({
          isSubmitting,
          values,
          setFieldValue,
          errors,
          resetForm,
          touched,
        }) => (
          <Form>
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <StyledItemWrap>
                    <StyledLabel
                      text="Название компании"
                      htmlFor="company-name"
                      inlineStyles={{
                        marginBottom: '16px',
                      }}
                    />
                    <StyledInput
                      field
                      type="text"
                      name="name"
                      id="company-name"
                      placeholder="ООО «Рога и копыта»"
                    />
                  </StyledItemWrap>
                  <ErrorMessage name="name" />

                  <StyledWrap>
                    <StyledItemWrap>
                      <StyledLabel
                        text="Город"
                        htmlFor="city"
                        inlineStyles={{
                          marginBottom: '16px',
                        }}
                      />
                      <SelectDiv>
                        <Field as="select" name="city">
                          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                          <option value="" hidden></option>
                          {companyCity.map((r: CompanyCity) => {
                            return (
                              <option key={r.id} value={r.name}>
                                {r.name}
                              </option>
                            );
                          })}
                        </Field>
                      </SelectDiv>
                      <ErrorMessage name="city" />
                    </StyledItemWrap>
                    <StyledItemWrap>
                      <StyledLabel
                        text="Часовой пояс"
                        htmlFor="time_zone"
                        inlineStyles={{
                          marginBottom: '16px',
                        }}
                      />
                      <SelectDiv>
                        <Field as="select" name="time">
                          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                          <option value="" hidden></option>
                          {dictionaries.companyTimezon?.map(
                            (r: CompanyTomeZone) => {
                              return (
                                <option key={r.VALUE} value={r.VALUE}>
                                  GMT+{r.VALUE}
                                </option>
                              );
                            },
                          )}
                        </Field>
                      </SelectDiv>
                      <div>
                        <ErrorMessage name="time_zone" />
                      </div>
                    </StyledItemWrap>
                  </StyledWrap>

                  <StyledItemWrap>
                    <StyledLabel
                      text="Адрес"
                      htmlFor="address"
                      inlineStyles={{
                        marginBottom: '16px',
                      }}
                    />
                    <StyledInput
                      field
                      name="address"
                      type="text"
                      id="address"
                      placeholder="г. Москва, ул. Барклая, д 6, стр 5, офис 328"
                    />
                    <div>
                      {errors.address && touched.address && errors.address}
                    </div>
                  </StyledItemWrap>

                  <StyledWrap>
                    <StyledItemWrap>
                      <StyledLabel
                        text="E-mail"
                        htmlFor="mail"
                        inlineStyles={{
                          marginBottom: '16px',
                        }}
                      />
                      <StyledInput
                        field
                        name="email"
                        type="email"
                        id="mail"
                        placeholder="info@mebot24-hr.ru"
                      />
                      <div>{errors.email && touched.email && errors.email}</div>
                    </StyledItemWrap>
                    <StyledItemWrap>
                      <StyledLabel
                        text="Телефон"
                        htmlFor="phone"
                        inlineStyles={{
                          marginBottom: '16px',
                        }}
                      />
                      <StyledInput
                        field
                        type="tel"
                        id="phone"
                        name="phone"
                        placeholder="+7 (988) 456-45-56"
                      />
                      <div>{errors.phone && touched.phone && errors.phone}</div>
                    </StyledItemWrap>
                  </StyledWrap>
                  <StyledItemWrap>
                    <StyledLabel
                      text="Описание компании"
                      htmlFor="description"
                      inlineStyles={{
                        marginBottom: '16px',
                      }}
                    />
                    <StyledInput
                      field
                      inlineStyles={{
                        minHeight: '218px',
                      }}
                      textareaField
                      name="description"
                      placeholder="Мы дизайн-студия. Создаем сайты и приложения."
                    />
                  </StyledItemWrap>
                  <div>
                    {errors.description &&
                      touched.description &&
                      errors.description}
                  </div>
                </div>
                <div className="col-lg-6">
                  <StyledLabel
                    text="Выберите цвет вашей компании"
                    inlineStyles={{
                      marginBottom: '16px',
                    }}
                    paragraph
                  />
                  <StyledBlocks>
                    {colorsData.map(item => {
                      return (
                        <StyledColorBlock
                          onClick={() => setColors(item.index)}
                          style={{ backgroundColor: bg as string }}
                          className={colors === item.index ? ' active' : ''}
                          type="button"
                          aria-label={item.color}
                          key={item.color}
                        >
                          <span className={item.color}></span>
                        </StyledColorBlock>
                      );
                    })}
                  </StyledBlocks>
                  <StyledLabel
                    text="Выберите фоновое изображение вашей компании"
                    inlineStyles={{
                      marginBottom: '16px',
                    }}
                    paragraph
                  />
                  <StyledCompanyBlocks>
                    {companies.map(company => {
                      return (
                        <StyledCompanyBlock
                          type="button"
                          onClick={() => setImages(company.index)}
                          className={images === company.index ? ' active' : ''}
                          key={company.image}
                        >
                          <div
                            className="wrapper"
                            style={{ backgroundColor: bg as string }}
                          >
                            <img src={company.image} alt="#" />
                          </div>
                        </StyledCompanyBlock>
                      );
                    })}
                  </StyledCompanyBlocks>
                  <div>{errImage && 'Вы не выбрали картину'}</div>
                </div>
              </div>
              <StyledButtonWrap>
                <StyledLink
                  text="Сохранить изменения"
                  inlineStyles={{
                    height: '50px',
                  }}
                  type="submit"
                  button
                ></StyledLink>
              </StyledButtonWrap>
            </div>
          </Form>
        )}
      </Formik>
    </StyledEditForm>
  );
};

export default CompanyForm;
