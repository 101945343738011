import React, { useEffect, useState } from 'react';
import './editVacancy.scss';
import Loader from 'react-loader-spinner';
import useTypedSelector from '../../../hooks/useTypedSelector';
import { Currency, Vacancy } from '../../../types/vacancies';
import { useActions } from '../../../hooks/useActions';

const EditVacancy: React.FunctionComponent = () => {
  const { singleVacancy } = useTypedSelector(r => r.vacancies.vacancy);
  const { dictionaries } = useTypedSelector(r => r.mainData);
  const { updateVacancy } = useActions();
  const [buttonLoader, setButtonLoader] = useState(false);
  const [vacancy, setVacancy] = useState(singleVacancy);
  useEffect(() => {
    setVacancy(singleVacancy);
  }, [singleVacancy]);
  const handleEditInput = (name: string, value: string | number) => {
    setVacancy((prevState: Vacancy) => {
      const eo = {
        [name]: value,
      };
      return { ...prevState, ...eo };
    });
  };
  const handleSaveVacancy = () => {
    setButtonLoader(true);
    const updWithLoader = async () => {
      try {
        await updateVacancy(singleVacancy.id, vacancy);
      } finally {
        setButtonLoader(false);
      }
    };

    setTimeout(() => {
      updWithLoader();
    }, 2000);
  };
  return (
    <>
      <div className="content-system-section">
        <div className="content-system-section__content">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-3">
                <h2 className="content-system-section__title">О вакансии</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <form className="vacancy-info-form" action="#">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="vacancy-info-form__row">
                        <div className="input-title">
                          <span>Название вакансии</span>
                        </div>
                        <input
                          className="dflt-input"
                          type="text"
                          name="name"
                          placeholder="Название вакансии"
                          defaultValue={vacancy.name}
                          onChange={e => {
                            handleEditInput(e.target.name, e.target.value);
                          }}
                        />
                      </div>
                      <div className="vacancy-info-form__row">
                        <div className="input-title">
                          <span>Компания</span>
                        </div>
                        <div className="row">
                          <div className="col-xl-12 col-sm-12 col-12">
                            <div className="form-select">
                              <select
                                className="form-select__source"
                                name="company"
                                defaultValue="3"
                              >
                                <option value="1">ООО "Рога и Копыта"</option>
                                <option value="2">ООО "Рога и Копыта"</option>
                                <option value="3">ООО "Рога и Копыта"</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="vacancy-info-form__row">
                        <div className="input-title">
                          <span>Заработная плата</span>
                        </div>
                        <div className="row">
                          <div className="col-xl-5 col-sm-4 col-4 small-col">
                            <input
                              className="dflt-input dflt-input_numeric"
                              type="number"
                              name="salary_from"
                              placeholder="от"
                              value={vacancy.salary_from}
                              onChange={e => {
                                handleEditInput(e.target.name, e.target.value);
                              }}
                            />
                          </div>
                          <div className="col-xl-5 col-sm-4 col-4 small-col">
                            <input
                              className="dflt-input dflt-input_numeric"
                              type="number"
                              name="salary_to"
                              placeholder="до"
                              value={vacancy.salary_to}
                              onChange={e => {
                                handleEditInput(e.target.name, e.target.value);
                              }}
                            />
                          </div>
                          <div className="col-xl-2 col-sm-4 col-4 small-col">
                            <div className="form-select">
                              <select
                                onChange={e => {
                                  handleEditInput(
                                    e.target.name,
                                    e.target.value,
                                  );
                                }}
                                name="currency_code"
                                value={vacancy.currency_code}
                              >
                                <option value={Currency.RUB}>руб</option>
                                <option value={Currency.EUR}>eur</option>
                                <option value={Currency.USD}>usd</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="vacancy-info-form__row">
                        <div className="input-title">
                          <span>Ссылка на источник размещения</span>
                        </div>
                        <textarea
                          className="dflt-textarea link-textarea"
                          name="url"
                          style={{ height: 92 }}
                          placeholder="Ссылка на источник размещения"
                          value={vacancy.url}
                          onChange={e => {
                            handleEditInput(e.target.name, e.target.value);
                          }}
                        />
                        <div className="vacancy-info-form__source-select">
                          <div className="vacancy-info-form__source-select-item">
                            <input
                              type="radio"
                              id="linkHH"
                              name="vacancyInfo6"
                              defaultChecked
                            />
                            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                            <label htmlFor="linkHH">hh</label>
                          </div>
                          <div className="vacancy-info-form__source-select-item">
                            <input
                              type="radio"
                              id="linkSuperjob"
                              name="vacancyInfo6"
                            />
                            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                            <label htmlFor="linkSuperjob">superjob</label>
                          </div>
                          <div className="vacancy-info-form__source-select-item">
                            <input
                              type="radio"
                              id="linkRabota"
                              name="vacancyInfo6"
                            />
                            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                            <label htmlFor="linkRabota">rabota</label>
                          </div>
                          <div className="vacancy-info-form__source-select-item">
                            <input
                              type="radio"
                              id="linkOther"
                              name="vacancyInfo6"
                            />
                            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                            <label htmlFor="linkOther">Другое</label>
                          </div>
                        </div>
                      </div>
                      <div className="vacancy-info-form__row">
                        <div className="input-title">
                          <span>Требования</span>
                        </div>
                        <textarea
                          className="dflt-textarea dflt-textarea__mobile"
                          name="text_requirements"
                          value={vacancy.text_requirements}
                          style={{ height: 179 }}
                          placeholder="Правила организации вашего коллектива, необходимые знания..."
                          onChange={e => {
                            handleEditInput(e.target.name, e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="vacancy-info-form__row special">
                        <div className="input-title">
                          <span>График</span>
                        </div>
                        <div className="row">
                          <div className="col-lg-12 col-md-12">
                            {dictionaries.vacancySchedule?.map(
                              (item: { id: number; title: string | null }) => {
                                return (
                                  <div className="dflt-radio" key={item.id}>
                                    <input
                                      type="radio"
                                      id={`schedule${item.id}`}
                                      name="type_schedule"
                                      value={item.id}
                                      checked={
                                        vacancy.type_schedule === item.id
                                      }
                                      onChange={e => {
                                        handleEditInput(
                                          e.target.name,
                                          Number(e.target.value),
                                        );
                                      }}
                                    />
                                    <label htmlFor={`schedule${item.id}`}>
                                      <span>{item.title}</span>
                                    </label>
                                  </div>
                                );
                              },
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="vacancy-info-form__row special">
                        <div className="input-title">
                          <span>Тип вакансии</span>
                        </div>
                        <div className="row">
                          {dictionaries.vacancyLocation?.map(
                            (item: { id: number; title: string | null }) => (
                              <div className="col-lg-6" key={item.id}>
                                <div className="dflt-radio">
                                  <input
                                    type="radio"
                                    id={`vacancytype${item.id}`}
                                    name="type_location"
                                    value={item.id}
                                    checked={vacancy.type_location === item.id}
                                    onChange={e => {
                                      handleEditInput(
                                        e.target.name,
                                        Number(e.target.value),
                                      );
                                    }}
                                  />
                                  <label htmlFor={`vacancytype${item.id}`}>
                                    <span>{item.title}</span>
                                  </label>
                                </div>
                              </div>
                            ),
                          )}
                        </div>
                      </div>
                      <div className="vacancy-info-form__row special">
                        <div className="input-title">
                          <span>Тип найма</span>
                        </div>
                        <div className="row">
                          {dictionaries.vacancyFrequency?.map(
                            (item: { id: number; title: string | null }) => (
                              <div className="col-lg-6" key={item.id}>
                                <div className="dflt-radio">
                                  <input
                                    type="radio"
                                    value={item.id}
                                    id={`worktype${item.id}`}
                                    name="type_frequency"
                                    checked={vacancy.type_frequency === item.id}
                                    onChange={e => {
                                      handleEditInput(
                                        e.target.name,
                                        Number(e.target.value),
                                      );
                                    }}
                                  />
                                  <label htmlFor={`worktype${item.id}`}>
                                    <span>{item.title}</span>
                                  </label>
                                </div>
                              </div>
                            ),
                          )}
                        </div>
                      </div>
                      <div className="vacancy-info-form__row">
                        <div className="input-title">
                          <span>Условия работы</span>
                        </div>
                        <textarea
                          className="dflt-textarea dflt-textarea__mobile"
                          name="text_conditions"
                          value={vacancy.text_conditions}
                          style={{ height: 112 }}
                          placeholder="Опишите режим работы, местонахождение компании..."
                          onChange={e => {
                            handleEditInput(e.target.name, e.target.value);
                          }}
                        />
                      </div>
                      <div className="vacancy-info-form__row">
                        <div className="input-title">
                          <span>Перечислите обязанности</span>
                        </div>
                        <textarea
                          className="dflt-textarea dflt-textarea__mobile"
                          name="text_responsibilities"
                          style={{ height: 252 }}
                          onChange={e => {
                            handleEditInput(e.target.name, e.target.value);
                          }}
                          placeholder="Какими навыками и знаниями должен обладать кандидат..."
                          value={vacancy.text_responsibilities}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <button
                        className="dflt-bttn"
                        type="button"
                        onClick={handleSaveVacancy}
                        disabled={buttonLoader}
                        style={{ opacity: buttonLoader ? 0.5 : 1 }}
                      >
                        Сохранить&nbsp;
                        {buttonLoader && (
                          <Loader
                            type="Audio"
                            color="#000"
                            height="16px"
                            width="16px"
                          />
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditVacancy;
