import { Dispatch } from 'redux';
import axios from 'axios';
import Moment from 'moment';
import { config, url } from './actionsAssets';
import {
  CVI,
  InterviewState,
  NewVacancy,
  SeekerReport,
  VacanciesActions,
  VacanciesActionTypes,
  Vacancy,
} from '../../types/vacancies';
import { DateTimeFormats } from '../../helpers/formatEnum';

export const clearSingleVacancy = () => {
  return (dispatch: Dispatch<VacanciesActions>) => {
    dispatch({
      type: VacanciesActionTypes.CLEAR_SINGLE_VACANCY,
    });
  };
};

export interface StatDates {
  dateFrom: string;
  dateTo: string;
}

export const fetchSingleVacancyStatistics = (
  id: string | number,
  dates: StatDates = {
    dateFrom: Moment()
      .startOf('month')
      .format(DateTimeFormats.DATE_FORMAT_API),
    dateTo: Moment()
      .endOf('month')
      .format(DateTimeFormats.DATE_FORMAT_API),
  },
) => {
  return async (dispatch: Dispatch<VacanciesActions>) => {
    try {
      const statistics = await axios.get(
        `${url}vacancy/${id}/statistics/?date_from=${dates.dateFrom}&date_to=${dates.dateTo}`,
        config,
      );
      return dispatch({
        type: VacanciesActionTypes.FETCH_SINGLE_VACANCY_STATISTIC,
        payload: statistics.data,
      });
    } catch (e) {
      return console.log(e);
    }
  };
};
export const fetchListCVS = (page = 0, limit = 20, id: string) => {
  const paginator = `&offset=${limit * page}`;
  return async (dispatch: Dispatch<VacanciesActions>) => {
    const list = await axios.get(
      `${url}resume/list/?vacancy_id=${id}&limit=${limit}${paginator}&metaInfo=1`,
      config,
    );
    return dispatch({
      type: VacanciesActionTypes.FETCH_SINGLE_VACANCY_CVS,
      payload: list.data,
    });
  };
};
export const fetchListVacancies = (page = 0, limit = 6, archived = false) => {
  let paginator = '';
  if (page >= 0) {
    paginator = `&offset=${limit * page}`;
  }

  return async (dispatch: Dispatch<VacanciesActions>) => {
    const list = await axios.get(
      `${url}vacancy/list/?limit=${limit}${paginator}&status=${
        archived ? 'N' : 'Y'
      }`,
      config,
    );
    return dispatch({
      type: archived
        ? VacanciesActionTypes.FETCH_LIST_VACANCIES_ARCHIVE
        : VacanciesActionTypes.FETCH_LIST_VACANCIES,
      payload: list.data,
    });
  };
};
export const fetchSingleVacancy = (id: string) => {
  return async (dispatch: Dispatch<VacanciesActions>) => {
    try {
      await dispatch({ type: VacanciesActionTypes.CLEAR_SINGLE_VACANCY });
      const vacancy = await axios.get(`${url}vacancy/${id}/`, config);
      return dispatch({
        type: VacanciesActionTypes.FETCH_SINGLE_VACANCY,
        payload: vacancy.data,
      });
    } catch (e) {
      return dispatch({
        type: VacanciesActionTypes.ERROR_FETCH_SINGLE_VACANCY,
      });
    }
  };
};

interface FetchSVInterviewPeriod {
  from: string;
  to: string;
}

export const fetchSvInterview = (
  id: string,
  { today = false, interviewer = '' },
  period?: FetchSVInterviewPeriod,
) => {
  return async (dispatch: Dispatch<VacanciesActions>) => {
    let dayFrom;
    let dayTo;
    if (today) {
      dayFrom = Moment().format(DateTimeFormats.DATE_FORMAT_API);
      dayTo = Moment().format(DateTimeFormats.DATE_FORMAT_API);
    }
    if (period) {
      dayFrom = period.from;
      dayTo = period.to;
    }
    // eslint-disable-next-line no-nested-ternary
    const dayString = today
      ? `&date_from=${dayFrom}&date_to=${dayTo}`
      : period
      ? `&date_from=${dayFrom}&date_to=${dayTo}`
      : '';
    try {
      const interviews = await axios.get(
        `${url}interview/list/?resume=Y&vacancy_id=${id}${dayString}&manager_id=${interviewer}&sortBy=date&sortDir=desc`,
        config,
      );
      return dispatch({
        type: today
          ? VacanciesActionTypes.FETCH_SINGLE_VACANCY_INTERVIEWS_TODAY
          : VacanciesActionTypes.FETCH_SINGLE_VACANCY_INTERVIEWS,
        payload: interviews.data,
      });
    } catch (e) {
      return dispatch({
        type: VacanciesActionTypes.ERROR_FETCH_SINGLE_VACANCY,
      });
    }
  };
};

export const addNewInterview = (interview: InterviewState) => {
  return axios.post(`${url}interview/add/`, interview, config);
};
export const addNewCv = (cv: CVI[]) => {
  return axios.post(`${url}resume/add/`, cv, config);
};

export const setPlacesInterview = (places: number, id: string) => {
  return axios.post(
    `${url}interview/${id}/set/place/`,
    { max_place: places },
    config,
  );
};

export const setInterviewCVReport = (
  interview_id: number | string,
  resume_id: number | string,
  report: SeekerReport,
) => {
  return axios.post(
    `${url}interview/${interview_id}/report/${resume_id}/`,
    report,
    config,
  );
};

export const setRegisterInterviewCV = (id: string, resume_id: string) => {
  return axios.post(
    `${url}interview/${id}/registration/add/`,
    { resume_id },
    config,
  );
};

export const setAssignerInterview = (assigner: string, id: string) => {
  return axios.post(
    `${url}interview/${id}/set/manager/`,
    { responsible_user: assigner },
    config,
  );
};

export const setInterviewSeekerStatus = (
  interview_id: number | string,
  resume_id: number | string,
  status: 'Y' | 'N' | '',
) => {
  return axios.patch(
    `${url}interview/${interview_id}/registration/${resume_id}/`,
    { status },
    config,
  );
};

export const addNewVacancy = (vacancy: NewVacancy) => {
  return axios.post(`${url}vacancy/add/`, vacancy, config);
};

export const updateVacancy = (id: string, vacancy: Vacancy, inList = false) => {
  return async (dispatch: Dispatch<VacanciesActions>) => {
    const item = await axios.put(`${url}vacancy/${id}/`, vacancy, config);
    return dispatch({
      type: inList
        ? VacanciesActionTypes.PUT_SINGLE_VACANCY
        : VacanciesActionTypes.FETCH_SINGLE_VACANCY,
      payload: item.data,
    });
  };
};
