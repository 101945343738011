import styled from 'styled-components';

export const ScheduleEvent = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;
`;

export const ScheduleEventNumber = styled.span`
  flex-shrink: 0;
  width: 55px;
  color: #4f4f4f;
  align-self: start;

  @media (min-width: 768px) {
    padding-top: 7px;
  }
`;

export const ScheduleEventWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 55px);

  .text {
    margin-bottom: 10px;
    @media (min-width: 768px) {
      width: 44%;
      margin-bottom: 0;
    }
  }

  .column {
    @media (min-width: 768px) {
      width: 19%;
    }
  }

  .wrapper {
    width: 56%;
    @media (max-width: 768px) {
      width: 100%;
    }

    @media (min-width: 768px) {
      margin-left: auto;
    }
  }

  .flex-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 10px;
    @media (min-width: 768px) {
      font-size: 15px;
      line-height: 21px;
      margin-bottom: 0px;
      margin-right: 43px;

      &:last-of-type {
        margin-right: 0;
        flex-grow: 1;
      }
    }
  }

  .name {
    width: 70%;
    @media (min-width: 768px) {
      margin-right: 35px;
      width: 180px;
    }
  }

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
`;
