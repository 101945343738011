import React, { useState } from 'react';

interface QuestionnaireItem {
  item: string;
}

const VacancyQuestionnaireItem: React.FC<QuestionnaireItem> = ({ item }) => {
  const [collapsed, setCollapsed] = useState(false);

  const handleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  return (
    <div className="card">
      <div className="card-header" id="headingOne">
        <button
          className="btn btn-link"
          type="button"
          data-toggle="collapse"
          data-target="#collapseOne"
          aria-expanded="true"
          aria-controls="collapseOne"
          onClick={handleCollapsed}
        >
          <div className="accordion-col">
            <div className="accordion-person">
              <div className="accordion-person__pict">ИИ</div>
              <div className="accordion-person__name">Иванов Иван Петрович</div>
            </div>
          </div>
          <div className="accordion-col">
            <div className="accordion-top">
              <div className="accordion-status">
                <div className="accordion-status__name">Статус:</div>
                <div className="accordion-status__desc accordion-status__green">
                  принят
                </div>
              </div>
              <div className="accordion-tests">
                <div className="accordion-status__name">Пройденные тесты:</div>
                <div className="accordion-tests__desc accordion-status__green">
                  <svg
                    width="9"
                    height="8"
                    viewBox="0 0 9 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="4.31372"
                      cy="4.01275"
                      r="3.92602"
                      fill="white"
                    />
                    <path
                      d="M3.00488 4.11387L4.05948 5.32142L5.62223 2.70407"
                      stroke="#27AE60"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Проверено
                </div>
              </div>
              <div className="arrow-btn">
                <svg
                  style={{
                    transform: collapsed ? 'rotate(180deg)' : '',
                  }}
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="13"
                    cy="13"
                    r="12.48"
                    transform="rotate(-90 13 13)"
                    stroke="#BDBDBD"
                    strokeWidth="1.04"
                  />
                  <path
                    d="M9 14C9.32 13.68 11.8 11.2 13 10L17 14"
                    stroke="#828282"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </button>
      </div>
      <div
        className={`collapse ${!collapsed && 'show'}`}
        id="collapseOne"
        aria-labelledby="headingOne"
        data-parent="#accordionPersons"
      >
        <div className="card-body">
          <div className="accordion-col__small">
            <div className="accordion-col__small-block">
              <div className="accordion-person__pict">ИН</div>
              <div className="accordion-person__name">Иванов Иван Петрович</div>
            </div>
            <div className="accordion-col__small-name">
              Возраст:<span>22 года</span>
            </div>
            <div className="accordion-col__small-name">
              E-mail:
              <span>
                {' '}
                <a className="link" href="mailto:info@rambler.ru">
                  info@rambler.ru
                </a>
              </span>
            </div>
            <div className="accordion-col__small-name">
              Тел:
              <span>
                {' '}
                <a className="link" href="tel:+79998889988">
                  +7 999 888 99 88{' '}
                </a>
              </span>
            </div>
            <a className="dflt-bttn" href="/">
              Резюме
            </a>
          </div>
          <div className="accordion-col__big">
            <h3 className="actions__title">Пройденные тесты</h3>
            <div className="test-row test-slider">
              <div className="test-item">
                <div className="test-item__top">
                  <svg
                    width="9"
                    height="8"
                    viewBox="0 0 9 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="4.31372"
                      cy="4.01275"
                      r="3.92602"
                      fill="white"
                    />
                    <path
                      d="M3.00488 4.11387L4.05948 5.32142L5.62223 2.70407"
                      stroke="#27AE60"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Проверено
                </div>
                <div className="test-item__bottom">
                  <div className="test-item__bottom-text">
                    <span>120</span>/140
                  </div>
                  <div className="test-item__bottom-text">Основы продаж</div>
                  <div className="dflt-select">
                    <a className="dflt-select__bttn" href="/">
                      Действия
                    </a>
                    <ul className="dflt-select__dropdown">
                      <li>
                        <a href="/">Пригласить на должность</a>
                      </li>
                      <li>
                        <a href="/">Тестировать</a>
                      </li>
                      <li>
                        <a href="/">Собеседовать</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="test-item">
                <div className="test-item__top test-item__top-deny">
                  <svg
                    width="9"
                    height="9"
                    viewBox="0 0 9 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="4.5" cy="4.5" r="4.5" fill="white" />
                    <path
                      d="M3.93707 1.77771L4.02632 5.37717H4.96336L5.05261 1.77771H3.93707ZM4.49484 7.23639C4.83322 7.23639 5.10095 6.97982 5.10095 6.6526C5.10095 6.32537 4.83322 6.0688 4.49484 6.0688C4.15646 6.0688 3.88873 6.32537 3.88873 6.6526C3.88873 6.97982 4.15646 7.23639 4.49484 7.23639Z"
                      fill="#FF6363"
                    />
                  </svg>
                  Нет проверки
                </div>
                <div className="test-item__bottom">
                  <div className="test-item__bottom-text">
                    <span>XXX</span>/140
                  </div>
                  <div className="test-item__bottom-text">
                    Тест на психологию
                  </div>
                  <div className="dflt-bttn">Проверить</div>
                </div>
              </div>
              <div className="test-item">
                <div className="test-item__top">
                  <svg
                    width="9"
                    height="8"
                    viewBox="0 0 9 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="4.31372"
                      cy="4.01275"
                      r="3.92602"
                      fill="white"
                    />
                    <path
                      d="M3.00488 4.11387L4.05948 5.32142L5.62223 2.70407"
                      stroke="#27AE60"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Проверено
                </div>
                <div className="test-item__bottom">
                  <div className="test-item__bottom-text">
                    <span>120</span>/140
                  </div>
                  <div className="test-item__bottom-text">Основы продаж</div>
                  <div className="dflt-select">
                    <a className="dflt-select__bttn" href="/">
                      Действия
                    </a>
                    <ul className="dflt-select__dropdown">
                      <li>
                        <a href="/">Пригласить на должность</a>
                      </li>
                      <li>
                        <a href="/">Тестировать</a>
                      </li>
                      <li>
                        <a href="/">Собеседовать</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="test-item">
                <div className="test-item__top test-item__top-deny">
                  <svg
                    width="9"
                    height="9"
                    viewBox="0 0 9 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="4.5" cy="4.5" r="4.5" fill="white" />
                    <path
                      d="M3.93707 1.77771L4.02632 5.37717H4.96336L5.05261 1.77771H3.93707ZM4.49484 7.23639C4.83322 7.23639 5.10095 6.97982 5.10095 6.6526C5.10095 6.32537 4.83322 6.0688 4.49484 6.0688C4.15646 6.0688 3.88873 6.32537 3.88873 6.6526C3.88873 6.97982 4.15646 7.23639 4.49484 7.23639Z"
                      fill="#FF6363"
                    />
                  </svg>
                  Необходима проверка
                </div>
                <div className="test-item__bottom">
                  <div className="test-item__bottom-text">
                    <span>XXX</span>/140
                  </div>
                  <div className="test-item__bottom-text">
                    Тест на психологию
                  </div>
                  <div className="dflt-bttn">Проверить</div>
                </div>
              </div>
              <div className="test-item">
                <div className="test-item__top">
                  <svg
                    width="9"
                    height="8"
                    viewBox="0 0 9 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="4.31372"
                      cy="4.01275"
                      r="3.92602"
                      fill="white"
                    />
                    <path
                      d="M3.00488 4.11387L4.05948 5.32142L5.62223 2.70407"
                      stroke="#27AE60"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Проверено
                </div>
                <div className="test-item__bottom">
                  <div className="test-item__bottom-text">
                    <span>120</span>/140
                  </div>
                  <div className="test-item__bottom-text">Основы продаж</div>
                  <div className="dflt-select">
                    <a className="dflt-select__bttn" href="/">
                      Действия
                    </a>
                    <ul className="dflt-select__dropdown">
                      <li>
                        <a href="/">Пригласить на должность</a>
                      </li>
                      <li>
                        <a href="/">Тестировать</a>
                      </li>
                      <li>
                        <a href="/">Собеседовать</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-bottom">
              <h3 className="actions__title">Комментарий руководителя:</h3>
              <div className="accordion-text-field">
                Поведение при собеседовании умеренное, большой опыт работы, но
                на повторные вопросы об отношении к бывшему коллективу отвечал с
                ухмылкой.
              </div>
              <div className="accordion-title">
                <h3 className="actions__title">Пройденное собеседование</h3>
                <a
                  className="accordion-title__link"
                  href="/"
                  data-toggle="modal"
                  data-target="#addReport"
                >
                  Редактировать
                </a>
              </div>
              <div className="accordion-row">
                <div className="accordion-row__statistic">
                  <div className="accordion-row__statistic-row">
                    <div className="statistic-row__item">Дата:</div>
                    <div className="statistic-row__item statistic-row__item-actual">
                      10.11.2019
                    </div>
                  </div>
                  <div className="accordion-row__statistic-row">
                    <div className="statistic-row__item">Время:</div>
                    <div className="statistic-row__item statistic-row__item-actual">
                      10:00
                    </div>
                  </div>
                  <div className="accordion-row__statistic-row">
                    <div className="statistic-row__item">Статус:</div>
                    <div className="statistic-row__item accordion-status__desc accordion-status__red">
                      Отклонен
                    </div>
                  </div>
                </div>
                <div className="accordion-row__grafic">
                  <div className="accordion-row__grafic-item">
                    <div className="accordion-row__grafic-rating">
                      <div className="percentage-circle circle-6 circle-yellow">
                        <div className="grafic-assesment">
                          <span>6</span>/10
                        </div>
                      </div>
                    </div>
                    <div className="accordion-row__grafic-text">
                      Общая оценка
                    </div>
                  </div>
                  <div className="accordion-row__grafic-item">
                    <div className="accordion-row__grafic-rating">
                      <div className="percentage-circle circle-8 circle-green">
                        <div className="grafic-assesment">
                          <span>8</span>/10
                        </div>
                      </div>
                    </div>
                    <div className="accordion-row__grafic-text">Навыки</div>
                  </div>
                  <div className="accordion-row__grafic-item">
                    <div className="accordion-row__grafic-rating">
                      <div className="percentage-circle circle-4 circle-red">
                        <div className="grafic-assesment">
                          <span>4</span>/10
                        </div>
                      </div>
                    </div>
                    <div className="accordion-row__grafic-text">Поведение</div>
                  </div>
                  <div className="accordion-row__grafic-item">
                    <div className="accordion-row__grafic-rating">
                      <div className="percentage-circle circle-5 circle-orange">
                        <div className="grafic-assesment">
                          <span>5</span>/10
                        </div>
                      </div>
                    </div>
                    <div className="accordion-row__grafic-text">
                      Перспектива
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default VacancyQuestionnaireItem;
