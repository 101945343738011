import { StaffRuleList } from '../types/companies';

export const getRuleActions = (staffData: StaffRuleList[], value: string) => {
  return staffData.map((n: StaffRuleList) => {
    let name;
    if (n.id === value) {
      name = n.name;
    }
    return name;
  });
};
