const token = process.env.REACT_APP_TOKEN;
export const url = 'https://api.hr.mebot24.ru/api/';
export const config = {
  headers: {
    Authorization: `Bearer ${token}`,
    withCredentials: true,
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
};
