import React from 'react';
import { Link } from 'react-router-dom';
import StyledLink from '../../Template/StyledComponents/StyledLink';
import {
  StyledVacanciesHeader,
  StyledVacanciesHeaderNavline,
  StyledVacanciesHeaderContent,
  StyledTitle,
  StyledTitleButton,
  StyledArrowButton,
  StyledLinkWrap,
  StyledStatisticHeader,
  StyledStatisticTitle,
  StyledStatisticBtn,
  StyledStatisticList,
  StyledStatisticBar,
  StyledStatisticItemInfo,
  StyledNearestInterviews,
  StyledNearestInterviewsHead,
  StyledSchedule,
  Novacancy,
  StyledStatisticLink,
} from './StyledVacanciesHeaderComponents';

const NoVacancy: React.FC = () => {
  return (
    <StyledVacanciesHeader className="novacancy-section">
      <StyledVacanciesHeaderNavline>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-5 col-lg-6 col-12">
              <StyledTitle>
                <StyledTitleButton type="button">
                  Название вакансии
                </StyledTitleButton>

                <span className="arrow-wrap">
                  <StyledArrowButton type="button" />
                </span>
              </StyledTitle>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-12">
              <StyledLinkWrap>
                <StyledLink
                  path="/#"
                  text="Запустить найм"
                  inlineStyles={{ height: '38px', padding: '10px' }}
                >
                  <svg
                    width="7"
                    height="9"
                    viewBox="0 0 7 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M-3.11727e-07 1.86852L-7.84686e-08 7.20485C-4.42203e-08 7.98836 0.860163 8.46752 1.52635 8.05511L5.67684 5.48577C6.29591 5.10253 6.31099 4.20733 5.70518 3.80345L1.5547 1.03647C0.890145 0.59343 -3.46639e-07 1.06982 -3.11727e-07 1.86852Z"
                      fill="black"
                    />
                  </svg>
                </StyledLink>
              </StyledLinkWrap>
            </div>
          </div>
        </div>
      </StyledVacanciesHeaderNavline>
      <StyledVacanciesHeaderContent>
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-12">
              <div>
                <StyledStatisticHeader>
                  <StyledStatisticTitle>
                    Статистика с 01.01.21 по 01.06.20
                  </StyledStatisticTitle>
                  <StyledStatisticBtn type="button">
                    Изменить дату
                    <svg
                      width="6"
                      height="10"
                      viewBox="0 0 6 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1C1.32 1.32 3.8 3.8 5 5L1 9"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </StyledStatisticBtn>
                </StyledStatisticHeader>
                <StyledStatisticList>
                  <li>
                    <StyledStatisticBar>
                      <span />
                      <span
                        style={{
                          width: '100%',
                          background: '#ffd441',
                        }}
                      />
                    </StyledStatisticBar>
                    <StyledStatisticItemInfo>
                      <span
                        className="mark"
                        style={{ background: '#ffd441' }}
                      />
                      <span className="title">Резюме</span>
                      <span className="count">
                        <span>600</span> чел.
                      </span>
                    </StyledStatisticItemInfo>
                  </li>
                  <li>
                    <StyledStatisticBar>
                      <span />
                      <span
                        style={{
                          width: '90%',
                          background: '#FFBF41',
                        }}
                      />
                    </StyledStatisticBar>
                    <StyledStatisticItemInfo>
                      <span
                        className="mark"
                        style={{ background: '#FFBF41' }}
                      />
                      <span className="title">Связались</span>
                      <span className="count">
                        <span>300</span> чел.
                      </span>
                    </StyledStatisticItemInfo>
                  </li>
                  <li>
                    <StyledStatisticBar>
                      <span />
                      <span
                        style={{
                          width: '70%',
                          background: '#FFAC41',
                        }}
                      />
                    </StyledStatisticBar>
                    <StyledStatisticItemInfo>
                      <span
                        className="mark"
                        style={{ background: '#FFAC41' }}
                      />
                      <span className="title">Заинтересованы</span>
                      <span className="count">
                        <span>800</span> чел.
                      </span>
                    </StyledStatisticItemInfo>
                  </li>
                  <li>
                    <StyledStatisticBar>
                      <span />
                      <span
                        style={{
                          width: '40%',
                          background: '#FF9A41',
                        }}
                      />
                    </StyledStatisticBar>
                    <StyledStatisticItemInfo>
                      <span
                        className="mark"
                        style={{ background: '#FF9A41' }}
                      />
                      <span className="title">Тестирование</span>
                      <span className="count">
                        <span>500</span> чел.
                      </span>
                    </StyledStatisticItemInfo>
                  </li>
                  <li>
                    <StyledStatisticBar>
                      <span />
                      <span
                        style={{
                          width: '20%',
                          background: '#FF8841',
                        }}
                      />
                    </StyledStatisticBar>
                    <StyledStatisticItemInfo>
                      <span
                        className="mark"
                        style={{ background: '#FF8841' }}
                      />
                      <span className="title">Собеседование</span>
                      <span className="count">
                        <span>300</span> чел.
                      </span>
                    </StyledStatisticItemInfo>
                  </li>
                  <li>
                    <StyledStatisticBar>
                      <span />
                      <span
                        style={{
                          width: '50%',
                          background: '#FF6F41',
                        }}
                      />
                    </StyledStatisticBar>
                    <StyledStatisticItemInfo>
                      <span
                        className="mark"
                        style={{ background: '#FF6F41' }}
                      />
                      <span className="title">Выход на работу</span>
                      <span className="count">
                        <span>70</span> чел.
                      </span>
                    </StyledStatisticItemInfo>
                  </li>
                </StyledStatisticList>
              </div>
            </div>
            <div className="col-lg-5 col-md-12">
              <StyledNearestInterviews>
                <StyledNearestInterviewsHead>
                  <StyledStatisticTitle>
                    Ближайшие собеседования
                  </StyledStatisticTitle>
                  <StyledStatisticLink
                    to="/"
                    className="nearest-interviews__show-more"
                  >
                    Смотреть все собеседования
                    <svg
                      width="6"
                      height="10"
                      viewBox="0 0 6 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1C1.32 1.32 3.8 3.8 5 5L1 9"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </StyledStatisticLink>
                </StyledNearestInterviewsHead>
                <div className="row">
                  <div className="col-xl-8 col-lg-7">
                    <StyledSchedule>
                      <div className="schedule-head">
                        <span>Сегодня</span>
                      </div>
                      <div className="schedule-content">
                        <div className="schedule-column">
                          <div className="schedule-row">
                            <span>17:00</span>
                            <span>5 чел.</span>
                          </div>
                          <div className="schedule-row">
                            <span>18:00</span>
                            <span>3 чел.</span>
                          </div>
                        </div>
                        <div className="schedule-column">
                          <div className="schedule-row">
                            <span>19:00</span>
                            <span>0 чел.</span>
                          </div>
                          <div className="schedule-row">
                            <span>20:00</span>
                            <span>5 чел.</span>
                          </div>
                          <div className="schedule-row">
                            <span>16:00</span>
                            <span>3 чел.</span>
                          </div>
                        </div>
                      </div>
                    </StyledSchedule>
                  </div>
                  <div className="col-xl-4 col-lg-5 d-none d-lg-block">
                    <StyledSchedule transparent>
                      <div className="schedule-head">
                        <span>Завтра</span>
                      </div>
                      <div className="schedule-content">
                        <div className="schedule-column">
                          <div className="schedule-row">
                            <span>15:00</span>
                            <span>3 чел.</span>
                          </div>
                          <div className="schedule-row">
                            <span>17:00</span>
                            <span>5 чел.</span>
                          </div>
                          <div className="schedule-row">
                            <span>18:00</span>
                            <span>3 чел.</span>
                          </div>
                        </div>
                      </div>
                    </StyledSchedule>
                  </div>
                </div>
              </StyledNearestInterviews>
            </div>
          </div>
        </div>
      </StyledVacanciesHeaderContent>
      <Novacancy>
        <div className="container">
          <div className="novacancy-block">
            <div className="novacancy-title">Информации по вакансии нет</div>
            <div className="novacancy-desc">
              Информация по статистике набора будет доступна только после
              создания вакансии
            </div>
          </div>
        </div>
      </Novacancy>
    </StyledVacanciesHeader>
  );
};
export default NoVacancy;
