import React, { useState } from 'react';
import styled from 'styled-components';
import { useLocation, useParams } from 'react-router-dom';
import StyledLinkRound from '../../Template/StyledComponents/StyledLinkRound';
import StyledLink from '../../Template/StyledComponents/StyledLink';

interface Params {
  id: string;
}

const StyledFormHeaderComponent = styled.div`
  padding-top: 14px;
  padding-bottom: 14px;
  background: #67b7f0;
  min-height: 65px;
`;

const StyledTitle = styled.h2`
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
  color: #fff;
  margin-bottom: 0;

  @media (max-width: 991px) {
    margin-bottom: 15px;
    font-size: 20px;
    line-height: 29px;
  }

  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 29px;
    margin-bottom: 10px;
  }
`;

const StyledButtonsWrap = styled.div`
  @media (max-width: 768px) {
    overflow-x: scroll;
    width: 100%;
    height: 38px;
  }
`;

const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    height: 100%;
    width: 580px;
  }
`;

interface PropsInterface {
  text?: string;
  additionalClass?: string;
  editCompany: boolean;
}

const FormHeader: React.FC<PropsInterface> = ({
  text,
  additionalClass,
  editCompany,
}) => {
  const params: Params = useParams();
  const { pathname } = useLocation();
  const indexPathEdit = pathname.indexOf('edit');
  return (
    <StyledFormHeaderComponent className={additionalClass}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-5 col-lg-4">
            <StyledTitle>{text}</StyledTitle>
          </div>
          {editCompany && (
            <div className="col-xl-7 col-lg-8">
              <StyledButtonsWrap>
                <StyledButtonsWrapper>
                  <StyledLinkRound
                    additionalClass={indexPathEdit > 0 ? 'active' : ''}
                    path={`/companies/${params.id}/edit`}
                    text="Редактировать компанию"
                  >
                    <svg
                      width="12"
                      height="11"
                      viewBox="0 0 12 11"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M7.84007 1.83576L10.0777 4.08434L4.41361 9.77619L2.17723 7.5276L7.84007 1.83576ZM11.7757 1.29345L10.7778 0.290658C10.3921 -0.0968862 9.76588 -0.0968862 9.37891 0.290658L8.42302 1.25123L10.6607 3.49984L11.7757 2.37938C12.0748 2.07878 12.0748 1.59403 11.7757 1.29345ZM1.00623 10.6882C0.965505 10.8723 1.13097 11.0374 1.31426 10.9926L3.80775 10.385L1.57137 8.13646L1.00623 10.6882Z" />
                    </svg>
                  </StyledLinkRound>
                  <StyledLinkRound
                    additionalClass={indexPathEdit < 0 ? 'active' : ''}
                    path={`/companies/${params.id}`}
                    text="Права доступа"
                  >
                    <svg
                      width="12"
                      height="11"
                      viewBox="0 0 12 11"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M5.2121 9.60921C5.1071 9.71483 4.96384 9.77376 4.81503 9.77376C4.66621 9.77376 4.52295 9.71483 4.41795 9.60921L1.24682 6.43756C0.917726 6.10847 0.917726 5.57482 1.24682 5.24634L1.64389 4.84917C1.97309 4.52007 2.50612 4.52007 2.83522 4.84917L4.81503 6.82908L10.1648 1.47924C10.494 1.15015 11.0275 1.15015 11.3561 1.47924L11.7532 1.87642C12.0823 2.20551 12.0823 2.73906 11.7532 3.06764L5.2121 9.60921Z" />
                    </svg>
                  </StyledLinkRound>
                  <StyledLinkRound
                    path="/companies/add/"
                    text="Добавить компанию"
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <ellipse cx="6.95674" cy="7" rx="6.95674" ry="7" />
                      <path
                        d="M7 4V10M10 7L4 7"
                        stroke="#67B7F0"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                    </svg>
                  </StyledLinkRound>
                </StyledButtonsWrapper>
              </StyledButtonsWrap>
            </div>
          )}
        </div>
      </div>
    </StyledFormHeaderComponent>
  );
};

export default FormHeader;
