import React, { useState } from 'react';
import { SeekerReport, VacancySeeker } from '../../../types/vacancies';
import { HandleModalReport } from './VacancyInterviewsDayItem';
import useTypedSelector from '../../../hooks/useTypedSelector';

const VacancyInterviewsAddReport: React.FC<{
  seeker: VacancySeeker;
  handleShowModalReport: HandleModalReport;
}> = ({ seeker, handleShowModalReport }) => {
  const { interviewReport }: { interviewReport: [] } = useTypedSelector(
    r => r.mainData.dictionaries,
  );
  const [report, setReport] = useState<SeekerReport>({
    ID: 0,
    INTERVIEW_ID: seeker.interview_id,
    MARK_1: 0,
    MARK_2: 0,
    MARK_3: 0,
    MARK_4: 0,
    RESUME_ID: seeker.resume_id,
    TEXT: '',
  });
  const mark = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  const handleReport = (name: string, value: string | number) => {
    setReport(prev => {
      return { ...prev, [name]: value };
    });
  };
  return (
    <div>
      <h3>Добавьте отчет по пройденному собеседованию с кандидатом</h3>
      <p>
        <span>
          {seeker.first_name.slice(0, 1)}
          {seeker.last_name.slice(0, 1)}
        </span>
        <br />
        {seeker.first_name} {seeker.last_name}
      </p>
      <p>
        Заполните необходимые данные, что-бы они отразились в анкете кандидата
      </p>
      <h4>Пройденное собеседование</h4>
      <div>
        {interviewReport.map((item: { id: number; title: string }) => (
          <div key={item.id}>
            {item.title}
            {mark.map((sMark: number) => (
              <label key={sMark}>
                <input
                  type="radio"
                  name={`MARK_${item.id}`}
                  value={sMark}
                  onChange={e => {
                    handleReport(e.target.name, Number(e.target.value));
                  }}
                />
                {sMark + 1}
              </label>
            ))}
          </div>
        ))}
      </div>
      <h4>Статус кандидата при приеме на должность</h4>
      <div>123</div>
      <h4>Пройденные тесты</h4>
      <div>123</div>
      <h4>Комментарии руководителя</h4>
      <div>
        <textarea
          name="TEXT"
          value={report.TEXT}
          onChange={e => handleReport(e.target.name, e.target.value)}
        />
      </div>
      <button
        type="button"
        onClick={() => {
          handleShowModalReport.closeWithSave(report);
        }}
      >
        Добавить отчет
      </button>
    </div>
  );
};

export default VacancyInterviewsAddReport;
