import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Flatpickr from 'react-flatpickr';
import { Russian } from 'flatpickr/dist/l10n/ru';
import moment from 'moment';
import useTypedSelector from '../../../hooks/useTypedSelector';
import { DateTimeFormats } from '../../../helpers/formatEnum';
import { CompanyStaff } from '../../../types/companies';
import { DateTimeInterface, InterviewState } from '../../../types/vacancies';
import { addNewInterview } from '../../../store/actions/vacancies';
import StyledLink from '../../Template/StyledComponents/StyledLink';
import StyledSelect from '../../Template/StyledComponents/StyledSelect';
import calendarIcon from '../../../assets/image/calendar-icon.svg';
import timeIcon from '../../../assets/image/time-icon.svg';

const StyledTitle = styled.h2`
  margin: 0;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 17px;
  line-height: 21px;
  text-align: center;
  color: #000000;
  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 29px;
  }
`;

const StyledInputWrapper = styled.div<{
  date?: boolean;
  time?: boolean;
  candidates?: boolean;
}>`
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;

  .flatpickr-input,
  & > input {
    display: block;
    width: 100%;
    border: 1px solid #e0e0e0;
    height: 42px;
    padding: 9px 15px;
    padding-bottom: 12px;
    font-size: 15px;
    line-height: 21px;
    color: #000000;
    border-radius: 5px;
    background-image: ${props => (props.date ? `url(${calendarIcon})` : '')};
    background-image: ${props => (props.time ? `url(${timeIcon})` : '')};
    background-position: calc(100% - 15px) center;
    background-size: 20px 20px;
    background-repeat: no-repeat;
    outline: none;

    &:hover,
    &:focus {
      border-color: #bdbdbd;
    }

    &::placeholder {
      font: inherit;
    }
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    display: none;
    appearance: none;
    -webkit-appearance: none;
  }

  textarea {
    display: block;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    width: 100%;
    height: 102px;
    padding: 10px 14px;
    resize: none;
    font-size: 13px;
    line-height: 20px;
    color: #828282;
    outline: none;

    &:hover,
    &:focus {
      border-color: #bdbdbd;
    }

    @media (min-width: 768px) {
      height: 86px;
    }
  }
`;

const StyledLabel = styled.label`
  display: block;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: #333333;
  margin-bottom: 7px;

  @media (min-width: 768px) {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 13px;
  }
`;

const StyledForm = styled.form`
  width: 100%;
  display: flex;
  padding: 0 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  margin-bottom: 17px;

  @media (min-width: 768px) {
    grid-column-gap: 28px;
    margin-bottom: 25px;
  }
`;

const StyledLabelButton = styled.label`
  margin: 0;
  display: block;
  font-size: 13px;
  line-height: 18px;
  border-radius: 5px;
  padding: 7px 10px;
  padding-left: 35%;
  padding-bottom: 5px;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.5s;
  outline: none;

  svg {
    display: none;
  }

  &:first-of-type {
    border: 2px solid rgba(39, 174, 96, 1);
    color: rgba(39, 174, 96, 1);
  }

  &:last-of-type {
    border: 2px solid #56ccf2;
    color: #56ccf2;
  }

  &:first-of-type:hover,
  &:first-of-type:focus {
    background-color: rgba(39, 174, 96, 1);
    color: #ffffff;
  }

  &:last-of-type:hover,
  &:last-of-type:focus {
    background-color: #56ccf2;
    color: #ffffff;
  }

  @media (min-width: 768px) {
    flex-direcion: row;
    padding: 12px 20px;
    padding-bottom: 10px;
    padding-left: 35%;
  }
`;

const StyledInput = styled.input`
  &:checked + label {
    font-weight: bold;
    filter: saturate(150%);
  }
  &:first-of-type:checked + label {
    background-color: rgba(39, 174, 96, 1);
    color: #ffffff;
  }
  &:last-of-type:checked + label {
    background-color: #56ccf2;
    color: #ffffff;
  }
  &:checked + label svg {
    display: inline;
  }
`;

const LabelIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    viewBox="0 0 16 16"
  >
    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
  </svg>
);

interface VacancyInterviewsAddInterface {
  handleShowModal: {
    show: () => void;
    close: () => void;
    closeWithSave: () => void;
  };
}

const VacancyInterviewsAdd: React.FC<VacancyInterviewsAddInterface> = ({
  handleShowModal,
}) => {
  const { singleVacancy } = useTypedSelector(r => r.vacancies.vacancy);
  const { staff } = useTypedSelector(r => r.companies);
  const [dateTime, setDateTime] = useState<DateTimeInterface>({
    date: moment().format(DateTimeFormats.DATE_FORMAT_API),
    time: moment().format('HH:mm'),
  });

  const [interviewState, setInterviewState] = useState<InterviewState>({
    id: '',
    vacancy_id: singleVacancy.id,
    date: `${dateTime.date} ${dateTime.time}`,
    responsible_user: '',
    max_place: 0,
    seekers: [],
  });

  useEffect(() => {
    setInterviewState(prev => {
      return { ...prev, date: `${dateTime.date} ${dateTime.time}` };
    });
  }, [dateTime]);

  const handleSaveInterview = () => {
    addNewInterview(interviewState)
      .then(() => {
        handleShowModal.closeWithSave();
      })
      .catch(e => {
        console.log(e);
      });
  };

  const handleChangeInterviewState = (value: string | number, type: string) => {
    if (type === 'time' || type === 'date') {
      setDateTime(prev => {
        return { ...prev, [type]: value };
      });
    } else {
      setInterviewState(prev => {
        return { ...prev, [type]: value };
      });
    }
  };

  return (
    <>
      <StyledTitle>Событие в графике собеседований</StyledTitle>
      <div className="row">
        <div className="col-lg-4">
          <StyledInputWrapper date>
            <StyledLabel htmlFor="eventDate">Дата</StyledLabel>
            <Flatpickr
              name="eventDate"
              onChange={(date, currentDateString) => {
                handleChangeInterviewState(
                  moment(currentDateString, 'DD.MM.YYYY').format(
                    DateTimeFormats.DATE_FORMAT_API,
                  ),
                  'date',
                );
              }}
              options={{
                locale: Russian,
                dateFormat: 'd.m.Y',
                mode: 'single',
                inline: false,
                disableMobile: true,
                defaultDate: interviewState.date,
                minDate: 'today',
                static: true,
              }}
            />
          </StyledInputWrapper>
        </div>
        <div className="col-lg-4">
          <StyledInputWrapper time>
            <StyledLabel htmlFor="eventTime">Время</StyledLabel>
            <Flatpickr
              name="eventTime"
              onChange={(dates, currentDateString) => {
                handleChangeInterviewState(
                  moment(currentDateString, 'HH:mm').format(
                    DateTimeFormats.TIME_FORMAT_API,
                  ),
                  'time',
                );
              }}
              options={{
                locale: Russian,
                dateFormat: 'H:i',
                mode: 'time',
                inline: false,
                disableMobile: true,
                defaultDate: moment().format('HH:mm'),
                minDate: 'today',
                static: true,
              }}
            />
          </StyledInputWrapper>
        </div>
        <div className="col-lg-4">
          <StyledInputWrapper>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <StyledLabel htmlFor="addEvent-block__number">
              Количество канд-ов
            </StyledLabel>
            <input
              id="addEvent-block__number"
              type="number"
              name="addEvent-persons"
              onChange={e => {
                handleChangeInterviewState(
                  // eslint-disable-next-line radix
                  parseInt(e.target.value),
                  'max_place',
                );
              }}
              value={interviewState.max_place}
              placeholder="45"
            />
          </StyledInputWrapper>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8">
          <StyledInputWrapper>
            <StyledLabel>Кто собеседует:</StyledLabel>
            <StyledSelect
              name="responsible_id"
              value={interviewState.responsible_user}
              inlineStyles={{
                height: '42px',
                padding: '10px 14px',
              }}
              handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChangeInterviewState(e.target.value, 'responsible_user');
              }}
            >
              <option value="">Любой сотрудник</option>
              {staff.map((item: CompanyStaff) => (
                <option key={item.user_id} value={item.user_id}>
                  {item.name} {item.last_name}
                </option>
              ))}
            </StyledSelect>
          </StyledInputWrapper>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <StyledLabel as="p">Выбор связи</StyledLabel>
        </div>
      </div>
      <div className="row">
        <StyledForm>
          <StyledInput
            className="visually-hidden"
            name="messenger"
            type="radio"
            value="1"
            id="WhatsApp"
          />
          <StyledLabelButton tabIndex={0} htmlFor="WhatsApp">
            WhatsApp
            {LabelIcon}
          </StyledLabelButton>
          <StyledInput
            className="visually-hidden"
            name="messenger"
            type="radio"
            value="2"
            id="Skype"
          />
          <StyledLabelButton tabIndex={0} htmlFor="Skype">
            Skype
            {LabelIcon}
          </StyledLabelButton>
        </StyledForm>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <StyledInputWrapper>
            <StyledLabel htmlFor="addEvent-block__textarea">
              Ссылка на группу
            </StyledLabel>
            <textarea
              id="addEvent-block__textarea"
              name="addEvent-block__textarea"
              placeholder="https://www.figma.com/file/O6GcB053NJ7YaxgPTp2FOU/%D0%9B%D0%B8%D1%87%D0%BD%D1%8B%D0%B9-%D0%BA%D0%B0%D0%B1%D0%B8%D0%BD%D0%B5%D1%82-HR?node-id=48%3A21"
            />
          </StyledInputWrapper>
        </div>
        <div className="col-lg-12">
          <StyledLink
            text="Добавить событие"
            handleСlick={handleSaveInterview}
            type="button"
            button
          />
        </div>
      </div>
    </>
  );
};
export default VacancyInterviewsAdd;
