import React from 'react';
import styled from 'styled-components';
import { Field } from 'formik';

const StyledInputComponent = styled(Field)`
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  width: 100%;
  height: 49px;
  padding: 12px 20px 16px 20px;
  font-size: 15px;
  line-height: 21px;
  letter-spacing: 0.374px;
  color: #333333;
  display: flex;
  align-items: center;
  box-shadow: none;
  outline: none;

  &:hover,
  &:focus {
    border-color: #bdbdbd;
  }

  &::placeholder {
    color: #bdbdbd;
  }

  &[type='number'] {
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

interface CustomStyles {
  minHeight?: string | number;
  height?: string | number;
  padding?: string | number;
  fontSize?: string | number;
  textAlign?: 'center';
}

interface PropsInterface {
  additionalClass?: string;
  inlineStyles?: CustomStyles;
  placeholder?: string;
  id?: string;
  type?: string;
  name?: string;
  textarea?: boolean;
  field?: boolean;
  textareaField?: boolean;
  max?: number;
}

const StyledInput: React.FC<PropsInterface> = ({
  textareaField,
  additionalClass,
  inlineStyles,
  placeholder,
  id,
  type,
  name,
  textarea,
  field,
  max,
}) => {
  let output;
  if (textarea) {
    output = (
      <StyledInputComponent
        className={additionalClass}
        style={inlineStyles}
        placeholder={placeholder}
        id={id}
        name={name}
        as="textarea"
      />
    );
  } else if (field) {
    output = (
      <StyledInputComponent
        className={additionalClass}
        style={inlineStyles}
        placeholder={placeholder}
        id={id}
        type={type}
        name={name}
        component={textareaField && 'textarea'}
      />
    );
  } else {
    output = (
      <StyledInputComponent
        className={additionalClass}
        style={inlineStyles}
        placeholder={placeholder}
        id={id}
        type={type}
        name={name}
        as="input"
        max={max}
      />
    );
  }
  return output;
};

export default StyledInput;
