/* eslint-disable prefer-const */
import produce from 'immer';
import moment from 'moment';
import {
  VacanciesActionTypes,
  VacancyInterview,
  VacancyInterviewUnixTime,
  VacancyStatistic,
} from '../../types/vacancies';

interface VAI {
  [key: string]: VacancyInterviewUnixTime[];
}

const statistics: VacancyStatistic = {
  resume_load: 0,
  contact_start: 0,
  contact_finish: 0,
  test_finish: 0,
  task_finish: 0,
  interview_finish: 0,
};

const vacanciesReducer = produce(
  (draft, action) => {
    switch (action.type) {
      case VacanciesActionTypes.FETCH_SINGLE_VACANCY: {
        draft.vacancy.singleVacancy = action.payload;
        draft.vacancy.loaded = true;
        draft.vacancy.error = false;
        return draft;
      }
      case VacanciesActionTypes.PUT_SINGLE_VACANCY: {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < draft.vacanciesList.vacancies.length; i++) {
          if (draft.vacanciesList.vacancies[i].id === action.payload.id) {
            draft.vacanciesList.vacancies[i] = action.payload;
            break;
          }
        }
        return draft;
      }
      case VacanciesActionTypes.FETCH_LIST_VACANCIES: {
        draft.vacanciesList.vacancies = action.payload.result;
        draft.vacanciesList.count = action.payload.totalResultCount;
        return draft;
      }
      case VacanciesActionTypes.FETCH_LIST_VACANCIES_ARCHIVE: {
        draft.vacanciesList.vacanciesArchived = action.payload.result;
        draft.vacanciesList.countArchived = action.payload.totalResultCount;
        return draft;
      }
      case VacanciesActionTypes.CLEAR_SINGLE_VACANCY: {
        draft.vacancy.loaded = false;
        draft.vacancy.error = false;
        return draft;
      }
      case VacanciesActionTypes.ERROR_FETCH_SINGLE_VACANCY: {
        draft.vacancy.error = true;
        return draft;
      }
      case VacanciesActionTypes.FETCH_SINGLE_VACANCY_STATISTIC: {
        draft.vacancy.statistic = action.payload;
        return draft;
      }
      case VacanciesActionTypes.FETCH_SINGLE_VACANCY_INTERVIEWS_TODAY: {
        let vacancies = action.payload.result;
        vacancies = vacancies.map((r: VacancyInterview) => {
          // eslint-disable-next-line no-param-reassign
          r.date = moment(r.date, 'DD.MM.YYYY hh:mm:ss').unix();
          return r;
        });
        draft.vacancy.interviews.interviewsToday = vacancies.sort(
          (a: VacancyInterviewUnixTime, b: VacancyInterviewUnixTime) =>
            a.date - b.date,
        );
        return draft;
      }
      case VacanciesActionTypes.FETCH_SINGLE_VACANCY_INTERVIEWS: {
        let vacancies = action.payload.result;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        vacancies = vacancies.map((r: VacancyInterview) => {
          // eslint-disable-next-line no-param-reassign
          r.date = moment(r.date, 'DD.MM.YYYY hh:mm:ss').unix();
          return r;
        });
        vacancies = vacancies.sort(
          (a: VacancyInterviewUnixTime, b: VacancyInterviewUnixTime) =>
            a.date - b.date,
        );
        let b: VAI = {};
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        vacancies.forEach(item => {
          const i = String(moment.unix(item.date).format('YYYYMMDD'));
          if (b[i]) {
            b[i].push(item);
          } else {
            b[i] = [];
            b[i].push(item);
          }
        });
        draft.vacancy.interviews.interviews = b;
        return draft;
      }
      case VacanciesActionTypes.FETCH_SINGLE_VACANCY_CVS: {
        draft.vacancy.cvs.cvs = action.payload.result;
        draft.vacancy.cvs.count = action.payload.totalResultCount;
        return draft;
      }
      case VacanciesActionTypes.PUT_SINGLE_VACANCY_CV: {
        return draft;
      }
      default: {
        return draft;
      }
    }
  },
  {
    vacanciesList: {
      vacancies: [],
      vacanciesArchived: [],
      countArchived: 0,
      count: 0,
      loaded: false,
    },
    vacancy: {
      singleVacancy: {},
      statistic: statistics,
      cvs: {
        cvs: [],
        singleCV: [],
        count: 0,
      },
      interviews: {
        interviews: {},
        interviewsToday: [],
        meta: {},
      },
      loaded: false,
      error: false,
    },
  },
);
export default vacanciesReducer;
