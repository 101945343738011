import React, { Dispatch, SetStateAction } from 'react';

import { Modal } from 'react-bootstrap';

import { Field, Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useActions } from '../../../../hooks/useActions';
import {
  CompanyStaff,
  StaticDataType,
  StaffRuleList,
} from '../../../../types/companies';

interface Props {
  modal: boolean;
  setModal: Dispatch<SetStateAction<boolean>>;
  edit: boolean;
  staffId: CompanyStaff;
  companyId: string;
  staticData: StaticDataType;
}

interface MyFormValues {
  name: string;
  last_name: string;
  email: string;
  type: string;
  rule_vacancy: string;
  rule_interview: string;
  rule_resume: string;
}

const style = { width: '580px', margin: '0 auto' };

const ViewCompanyForm: React.FunctionComponent<Props> = ({
  modal,
  setModal,
  edit,
  staffId,
  companyId,
  staticData,
}) => {
  const initialValues: MyFormValues = {
    name: staffId ? staffId.name : '',
    last_name: staffId ? staffId.last_name : '',
    email: staffId ? 'info@mebot24-hr.ru' : '',
    type: staffId ? staffId.type : '',
    rule_vacancy: staffId ? staffId.rule_vacancy : '',
    rule_interview: staffId ? staffId.rule_interview : '',
    rule_resume: staffId ? staffId.rule_resume : '',
  };
  const { createCompanyStaff, updateCompanyStaff } = useActions();
  return (
    <>
      <Modal show={modal} onHide={() => setModal(false)}>
        <Modal.Body>
          <Formik
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Вы не заполнили поле'),
              last_name: Yup.string().required('Вы не заполнили поле'),
              email: Yup.string().required('Вы не заполнили поле'),
              rule_vacancy: Yup.string().required('Вы не заполнили поле'),
              type: Yup.string().required('Вы не заполнили поле'),
              rule_interview: Yup.string().required('Вы не заполнили поле'),
              rule_resume: Yup.string().required('Вы не заполнили поле'),
            })}
            initialValues={initialValues}
            onSubmit={(values, actions) => {
              if (staffId) {
                updateCompanyStaff(companyId, values, staffId.user_id);
              } else {
                createCompanyStaff(companyId, values);
              }
            }}
          >
            {({ errors, touched }) => (
              <div className="modal-body" style={style}>
                <div className="new-account__title">
                  Добавление нового пользователя
                </div>

                <Form className="new-account__form" action="#">
                  <div className="new-account__row">
                    <div className="new-account__row-item">
                      <label className="new-account__label" htmlFor="name">
                        Имя
                      </label>
                      <Field
                        disabled={staffId}
                        className="new-account__input"
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Александр"
                      />
                      {errors.name && touched.name && errors.name}
                    </div>
                    <div className="new-account__row-item">
                      <label className="new-account__label" htmlFor="last_name">
                        Фамилия
                      </label>
                      <Field
                        disabled={staffId}
                        className="new-account__input"
                        type="text"
                        id="secondName"
                        name="last_name"
                        placeholder="Филиппов"
                      />
                      {errors.last_name &&
                        touched.last_name &&
                        errors.last_name}
                    </div>
                  </div>
                  <div className="new-account__row">
                    <div className="new-account__row-item">
                      <label className="new-account__label" htmlFor="email">
                        E-mail
                      </label>
                      <Field
                        disabled={staffId}
                        className="new-account__input"
                        type="email"
                        id="email"
                        name="email"
                        placeholder="info@mebot24-hr.ru"
                      />
                      {errors.email && touched.email && errors.email}
                    </div>
                    <div className="new-account__row-item">
                      <label className="new-account__label" htmlFor="type">
                        Должность
                      </label>
                      <Field
                        disabled={staffId}
                        id="type"
                        as="select"
                        className="header-dropdown__list"
                        name="type"
                      >
                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                        <option hidden></option>
                        {staticData.ruleList.map((n: StaffRuleList) => (
                          <option value={n.id}>{n.name}</option>
                        ))}
                      </Field>
                      {errors.type && touched.type && errors.type}
                    </div>
                  </div>
                  <div className="new-account__row several">
                    <div className="new-account__row-select">
                      <label
                        className="new-account__label"
                        htmlFor="rule_vacancy"
                      >
                        Вакансии
                      </label>
                      <div className="new-account__dropdown">
                        <Field
                          as="select"
                          className="header-dropdown__list"
                          name="rule_vacancy"
                          id="rule_vacancy"
                        >
                          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                          <option hidden></option>
                          {staticData.ruleAction.map((n: StaffRuleList) => (
                            <option value={n.id}>{n.name}</option>
                          ))}
                        </Field>
                      </div>
                      {errors.rule_vacancy &&
                        touched.rule_vacancy &&
                        errors.rule_vacancy}
                    </div>
                    <div className="new-account__row-select">
                      <label
                        className="new-account__label"
                        htmlFor="rule_interview"
                      >
                        Собеседования
                      </label>
                      <div className="new-account__dropdown">
                        <Field
                          as="select"
                          className="header-dropdown__list"
                          name="rule_interview"
                        >
                          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                          <option hidden></option>
                          {staticData.ruleAction.map((n: StaffRuleList) => (
                            <option value={n.id}>{n.name}</option>
                          ))}
                        </Field>
                      </div>
                      {errors.rule_interview &&
                        touched.rule_interview &&
                        errors.rule_interview}
                    </div>
                    <div className="new-account__row-select">
                      <label
                        className="new-account__label"
                        htmlFor="rule_resume"
                      >
                        Анкеты
                      </label>
                      <div className="new-account__dropdown">
                        <Field
                          as="select"
                          className="header-dropdown__list"
                          name="rule_resume"
                        >
                          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                          <option hidden></option>
                          {staticData.ruleAction.map((n: StaffRuleList) => (
                            <option value={n.id}>{n.name}</option>
                          ))}
                        </Field>
                      </div>
                      {errors.rule_resume &&
                        touched.rule_resume &&
                        errors.rule_resume}
                    </div>
                  </div>
                  <button className="new-account__row-btn" type="submit">
                    Сохранить
                  </button>
                </Form>
              </div>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewCompanyForm;
