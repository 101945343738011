import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Switch, Route, useLocation } from 'react-router-dom';
import Vacancies from './components/Vacancies/Vacancies';
import Profile from './components/Profile/Profile';
import Catalog from './components/Catalog/Catalog';
import Basket from './components/Basket/Basket';
import MainPage from './components/MainPage/MainPage';
import Header from './components/Template/Header/Header';
import VacancyDisplay from './components/Template/VacancyDisplay/VacancyDisplay';
import AddNewVacancy from './components/Vacancies/AddNewVacancy/AddNewVacancy';
import VacanciesItem from './components/Vacancies/VacanciesItem/VacanciesItem';
import AddNewCompany from './components/Companies/AddNewCompany/AddNewCompany';
import EditCompany from './components/Companies/EditCompany/EditCompany';
import ViewCompany from './components/Companies/ViewCompany/ViewCompany';
import Companies from './components/Companies/Companies';

const App: React.FC = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Header />
      <VacancyDisplay />
      <main className="main" id="main">
        <Switch>
          <Route path="/about">about</Route>
          <Route path="/companies/" exact>
            <Companies />
          </Route>
          <Route path="/companies/add/">
            <AddNewCompany />
          </Route>
          <Route path="/companies/:id/edit/">
            <EditCompany />
          </Route>
          <Route path="/companies/:id/">
            <ViewCompany />
          </Route>
          <Route path="/vacancies/add" exact>
            <AddNewVacancy />
          </Route>
          <Route path="/vacancies/:id">
            <VacanciesItem />
          </Route>
          <Route path="/vacancies/" exact>
            <Vacancies />
          </Route>
          <Route path="/catalog/">
            <Catalog />
          </Route>
          <Route path="/profile/">
            <Profile />
          </Route>
          <Route path="/basket/">
            <Basket />
          </Route>
          <Route path="/" exact>
            <MainPage />
          </Route>
          <Route>404</Route>
        </Switch>
      </main>
    </>
  );
};

export default App;
