import React from 'react';
import styled from 'styled-components';

const StyledLabelComponent = styled.label`
  display: block;
  margin: 0;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
  margin-bottom: 11px;
`;

interface CustomStyles {
  marginBottom: string | number;
}

interface PropsInterface {
  text?: string;
  additionalClass?: string;
  inlineStyles?: CustomStyles;
  htmlFor?: string;
  paragraph?: boolean;
}

const StyledLabel: React.FC<PropsInterface> = ({
  text,
  additionalClass,
  inlineStyles,
  htmlFor,
  paragraph,
}) => {
  return paragraph ? (
    <StyledLabelComponent
      className={additionalClass}
      style={inlineStyles}
      as="p"
    >
      {text}
    </StyledLabelComponent>
  ) : (
    <StyledLabelComponent
      className={additionalClass}
      style={inlineStyles}
      htmlFor={htmlFor}
    >
      {text}
    </StyledLabelComponent>
  );
};

export default StyledLabel;
