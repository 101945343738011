import React, { SetStateAction, useState } from 'react';
import { Modal } from 'react-bootstrap';
import useTypedSelector from '../../../hooks/useTypedSelector';
import VacancyCVsTableRow from './VacancyCVsTableRow';
import { CVS } from '../../../types/vacancies';
import VacancyCVsInvite from './VacancyCVsInvite';

interface Pagination {
  page: number;
  setPage: (value: number | ((prevVar: number) => number)) => void;
}

const VacancyCVsTable: React.FC<Pagination> = ({ page = 0, setPage }) => {
  const { cvs } = useTypedSelector(r => r.vacancies.vacancy);
  const [modal, setModal] = useState(false);
  const [inv, setInv] = useState({
    company_id: '',
    first_name: '',
    last_name: '',
    year_of_birthday: '',
    date_create: '',
    file_id: '',
    id: '',
    last_update: '',
    seeker_id: '',
    url: '',
    vacancy_id: '',
  });
  const limit = 10;
  const handleChangePage = (pageNumber: number) => {
    setPage(pageNumber);
  };

  const handleModal = (item: CVS) => {
    setInv(item);
    setModal(true);
  };
  return (
    <>
      <Modal
        size="xl"
        show={modal}
        onHide={() => {
          setModal(false);
        }}
        className="modal fade addResume"
      >
        <Modal.Body className="big">
          {inv && <VacancyCVsInvite item={inv} handleModal={setModal} />}
        </Modal.Body>
      </Modal>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="resume-total-count">
              Всего: <span>{cvs.count} </span>резюме
            </div>
            <div className="resume-table">
              <div className="resume-table__heading">
                <span>ФИО</span>
                <span>Возраст</span>
                <span>Добавлено</span>
                <span>Изменено</span>
                <span>Ссылка</span>
              </div>
              <ul className="resume-table__list">
                {cvs.cvs.map((item: CVS) => (
                  <VacancyCVsTableRow
                    item={item}
                    key={item.id}
                    handleModal={handleModal}
                  />
                ))}
              </ul>
            </div>
            {cvs.cvs.length > limit && (
              <div className="resume-page-nav">
                <div className="row align-items-center">
                  <div className="col-lg-4 offset-lg-4">
                    <button
                      type="button"
                      className="dflt-bttn resume-page-nav__go-next"
                      onClick={() => {
                        handleChangePage(page + 1);
                      }}
                      disabled={page >= cvs.count / limit - 1}
                    >
                      Следующая страница
                    </button>
                  </div>
                  <div className="col-lg-4">
                    <div className="resume-page-nav__pagination">
                      <button
                        className="resume-page-nav__pagination-prev"
                        type="button"
                        onClick={() => {
                          handleChangePage(page - 1);
                        }}
                        disabled={page === 0}
                      >
                        <svg
                          width="6"
                          height="10"
                          viewBox="0 0 6 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5 1C4.68 1.32 2.2 3.8 1 5L5 9"
                            stroke="#BDBDBD"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                      <input
                        className="resume-page-nav__pagination-input"
                        type="text"
                        name="page"
                        value={page + 1}
                      />
                      <button
                        className="resume-page-nav__pagination-next"
                        type="button"
                        onClick={() => {
                          handleChangePage(page + 1);
                        }}
                        disabled={page >= cvs.count / limit - 1}
                      >
                        <svg
                          width="6"
                          height="10"
                          viewBox="0 0 6 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 1C1.32 1.32 3.8 3.8 5 5L1 9"
                            stroke="#828282"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                      <div className="resume-page-nav__pagination-count">
                        <span>из {Math.ceil(cvs.count / limit)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default VacancyCVsTable;
