import { combineReducers } from 'redux';
import mainDataReducer from './reducers/mainData';
import vacanciesReducer from './reducers/vacancies';
import companiesReducer from './reducers/companies';

const reducer = combineReducers({
  mainData: mainDataReducer,
  vacancies: vacanciesReducer,
  companies: companiesReducer,
});

export default reducer;
export type RootState = ReturnType<typeof reducer>;
