import React from 'react';
import { Link } from 'react-router-dom';
import { Route, Switch } from 'react-router';
import CatalogItem from './CatalogItem/CatalogItem';

const Catalog: React.FunctionComponent = () => {
  return (
    <>
      <nav>
        <ul>
          <li>
            <Link to="/catalog/341">Услуга 341</Link>
          </li>
        </ul>
      </nav>
      <div>Catalog</div>
      <Switch>
        <Route path="/catalog/:id">
          <CatalogItem />
        </Route>
      </Switch>
    </>
  );
};
export default Catalog;
