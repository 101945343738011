import React from 'react';
import moment from 'moment';
import { CVS } from '../../../types/vacancies';
import { DateTimeFormats } from '../../../helpers/formatEnum';
import { wordDeclination, wordsDict } from '../../../helpers/wordDeclination';

interface CvsProp {
  item: CVS;
  handleModal: (e: CVS) => void;
}

const VacancyCVsTableRow: React.FC<CvsProp> = ({ item, handleModal }) => {
  return (
    <li className="resume-table-list-item">
      <div className="resume-table-list-item__info">
        <span>
          {item.first_name} {item.last_name}
        </span>
        <span>
          {moment().diff(
            moment(item.year_of_birthday, DateTimeFormats.DATE_FORMAT_API),
            'years',
          )}
          &nbsp;
          {wordDeclination(
            moment().diff(
              moment(item.year_of_birthday, DateTimeFormats.DATE_FORMAT_API),
              'years',
            ),
            wordsDict.YEARS_FORMS,
          )}
        </span>
        <span>
          {moment(item.date_create, 'DD.MM.YYYY').format('DD.MM.YYYY')}
        </span>
        <span>
          {item.last_update
            ? moment(item.last_update, 'DD.MM.YYYY').format('DD.MM.YYYY')
            : '-'}
        </span>
        <span>
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a href={item.url} target="_blank">
            {/*            {item.url
              .split('/')
              .slice(0, 3)
              .join('/')}*/}
            /
          </a>
        </span>
      </div>
      <div className="resume-table-list-item__action">
        <div className="dflt-select dflt-select_mouseover">
          {/*          <button className="dflt-select__bttn" type="button">
            Действия
          </button>
          <button className="dflt-select__bttn" type="button">
            Пригласить <br />
            на должность
          </button>
          <button className="dflt-select__bttn" type="button">
            Тестировать
          </button>*/}
          <button
            className="dflt-select__bttn"
            type="button"
            onClick={() => handleModal(item)}
          >
            Собеседовать
          </button>
        </div>
      </div>
    </li>
  );
};
export default VacancyCVsTableRow;
