import React from 'react';
import StyledLink from '../Template/StyledComponents/StyledLink';
import VacanciesCard from './VacanciesCard';
import useTypedSelector from '../../hooks/useTypedSelector';
import { Vacancy } from '../../types/vacancies';
import { useActions } from '../../hooks/useActions';
import VacanciesCardArchive from './VacanciesCardArchive';

interface Pagination {
  page: number;
  setPage: (value: number) => void;
  archPage: number;
  setArchPage: (value: number) => void;
  refetchAll: () => void;
}

const VacanciesList: React.FC<Pagination> = ({
  page,
  setPage,
  archPage,
  setArchPage,
  refetchAll,
}) => {
  const { vacanciesList } = useTypedSelector(r => r.vacancies);
  const { updateVacancy } = useActions();
  const limit = 6;
  const forcePage = () => {
    if (page < vacanciesList.count / limit - 1) {
      setPage(page + 1);
    }
  };
  const forceArchPage = () => {
    if (archPage < vacanciesList.countArchived / limit - 1) {
      setArchPage(archPage + 1);
    }
  };
  const handleChangePage = (pageNumber: number) => {
    setPage(pageNumber);
  };
  const handleChangeArchPage = (pageNumber: number) => {
    setArchPage(pageNumber);
  };
  const handleVacancy = (vacancy: Vacancy) => {
    const newItem = { ...vacancy };
    if (vacancy.status === 'Y') {
      newItem.status = 'N';
    } else {
      newItem.status = 'Y';
    }

    const fetchAfterUpdate = async () => {
      await updateVacancy(newItem.id, newItem, true);
    };
    fetchAfterUpdate().then(() => {
      refetchAll();
    });
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="accordion-types">
            <button
              className="btn btn-primary"
              data-toggle="collapse"
              type="button"
              aria-expanded="false"
              aria-controls="multiCollapseExample1"
            >
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="13"
                  cy="13"
                  r="12.48"
                  transform="rotate(-90 13 13)"
                  stroke="#BDBDBD"
                  strokeWidth="1.04"
                />
                <path
                  d="M9 14C9.32 13.68 11.8 11.2 13 10L17 14"
                  stroke="#828282"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Актуальные вакансии
            </button>
            <div
              className="multi-collapse actual-main"
              id="multiCollapseExample1"
            >
              <div className="card card-body">
                <div className="card-body__button-wrap">
                  <StyledLink
                    text="Добавить вакансию"
                    path="/vacancies/add"
                    inlineStyles={{
                      height: '38px',
                    }}
                  >
                    <svg
                      width="15"
                      height="14"
                      viewBox="0 0 15 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <ellipse
                        cx="7.60469"
                        cy="7"
                        rx="6.95674"
                        ry="7"
                        fill="black"
                      />
                      <path
                        d="M7.60428 5V9M9.59192 7L5.61664 7"
                        stroke="#FEDC2D"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                    </svg>
                  </StyledLink>
                </div>
                {vacanciesList.vacancies.map((v: Vacancy) => (
                  <VacanciesCard
                    vacancy={v}
                    key={v.id}
                    handleVacancy={handleVacancy}
                  />
                ))}
                {vacanciesList.count > 0 ? (
                  <div className="company-bottom__btn">
                    <div className="company-bottom__btn-next">
                      <StyledLink
                        text="Следующая страница"
                        inlineStyles={{
                          height: '50px',
                        }}
                        handleСlick={forcePage}
                        disabled={page >= vacanciesList.count / limit}
                        type="button"
                        button
                      />
                    </div>
                    <div className="company-bottom__footer">
                      <button
                        type="button"
                        onClick={() => {
                          handleChangePage(page - 1);
                        }}
                        disabled={page === 0}
                        className="btn-left btn-arrow"
                      >
                        <svg
                          width="6"
                          height="10"
                          fill="none"
                          viewBox="0 0 6 10"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5 1C4.68 1.32 2.2 3.8 1 5L5 9"
                            stroke="#BDBDBD"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                      <div className="company-bottom__numb">{page + 1}</div>
                      <button
                        type="button"
                        className="btn-right btn-arrow"
                        onClick={() => {
                          handleChangePage(page + 1);
                        }}
                        disabled={page >= vacanciesList.count / limit - 1}
                      >
                        <svg
                          width="6"
                          height="10"
                          viewBox="0 0 6 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 1C1.32 1.32 3.8 3.8 5 5L1 9"
                            stroke="#828282"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                      <div className="company-bottom__all">
                        из<span>{Math.ceil(vacanciesList.count / limit)}</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>Активных вакансий нет</>
                )}
              </div>
            </div>
            <button
              className="btn btn-primary archive-bttn"
              type="button"
              data-toggle="collapse"
              data-target="#multiCollapseExample2"
              aria-expanded="false"
              aria-controls="multiCollapseExample2"
            >
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="13"
                  cy="13"
                  r="12.48"
                  transform="rotate(-90 13 13)"
                  stroke="#BDBDBD"
                  strokeWidth="1.04"
                />
                <path
                  d="M9 14C9.32 13.68 11.8 11.2 13 10L17 14"
                  stroke="#828282"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Архивные вакансии
            </button>
            <div className="multi-collapse" id="multiCollapseExample2">
              <div className="card card-body archive-body">
                {vacanciesList.vacanciesArchived.map((item: Vacancy) => (
                  <VacanciesCardArchive
                    vacancy={item}
                    key={item.id}
                    handleVacancy={handleVacancy}
                  />
                ))}
                {vacanciesList.countArchived > 0 ? (
                  <div className="company-bottom__btn">
                    <div className="company-bottom__btn-next">
                      <StyledLink
                        path="/"
                        text="Следующая страница"
                        handleСlick={forceArchPage}
                        disabled={page >= vacanciesList.countArchived / limit}
                        inlineStyles={{
                          height: '50px',
                        }}
                        button
                      />
                    </div>
                    <div className="company-bottom__footer">
                      <button
                        className="btn-left btn-arrow"
                        type="button"
                        onClick={() => {
                          handleChangeArchPage(archPage - 1);
                        }}
                        disabled={archPage === 0}
                      >
                        <svg
                          width="6"
                          height="10"
                          fill="none"
                          viewBox="0 0 6 10"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5 1C4.68 1.32 2.2 3.8 1 5L5 9"
                            stroke="#BDBDBD"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                      <div className="company-bottom__numb">{archPage + 1}</div>
                      <button
                        className="btn-right btn-arrow"
                        type="button"
                        onClick={() => {
                          handleChangeArchPage(archPage + 1);
                        }}
                        disabled={
                          archPage >= vacanciesList.countArchived / limit - 1
                        }
                      >
                        <svg
                          width="6"
                          height="10"
                          viewBox="0 0 6 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 1C1.32 1.32 3.8 3.8 5 5L1 9"
                            stroke="#828282"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                      <div className="company-bottom__all">
                        из
                        <span>
                          {Math.ceil(vacanciesList.countArchived / limit)}
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>Архивных вакансий нет</>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VacanciesList;
