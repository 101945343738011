import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import CompaniesCard from './CompaniesCard';
import useTypedSelector from '../../hooks/useTypedSelector';
import StyledLink from '../Template/StyledComponents/StyledLink';

import { CompaniesDataType } from '../../types/companies';
import { useActions } from '../../hooks/useActions';

const StyledCompaniesList = styled.div`
  margin-top: 40px;

  @media (max-width: 991px) {
    margin-top: 20px;
  }
`;

const StyledCompaniesTitle = styled.h2`
  @media (max-width: 991px) {
    margin-bottom: 18px;
    font-size: 20px;
    line-height: 29px;
    align-items: flex-end;
    display: flex;
  }
`;

const StyledCompaniesAddBtnWrap = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: flex-end;

  @media (max-width: 991px) {
    justify-content: flex-start;
    margin-bottom: 20px;
  }
`;

const StyledCompaniesAddBtn = styled.h2`
  max-width: 194px;
  width: 100%;
`;

const Companies: React.FunctionComponent = () => {
  const {
    fetchCompaniesPage,
    resetCompanySuccess,
    fetchCompanies,
  } = useActions();
  const { companiesPage } = useTypedSelector(r => r.companies);
  const { dictionaries } = useTypedSelector(r => r.mainData);
  const [count, setCount] = useState(1);
  const limit = 6;

  useEffect(() => {
    fetchCompaniesPage(limit, count);
  }, [count]);
  const handleChangeCount = (value: number) => {
    if (count === Number(companiesPage.totalCount)) return;
    setCount(prev => (prev === 1 && value < 0 ? 1 : prev + value));
  };

  useEffect(() => {
    fetchCompanies();
    resetCompanySuccess();
  }, []);

  return (
    <>
      <div className="all-companies">
        <StyledCompaniesList>
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <StyledCompaniesTitle className="important__title">
                  Мои компании
                </StyledCompaniesTitle>
              </div>
              <div className="col-lg-4">
                <StyledCompaniesAddBtnWrap>
                  <StyledCompaniesAddBtn>
                    <StyledLink
                      text="Добавить компанию"
                      path="/companies/add/"
                      inlineStyles={{
                        height: '38px',
                      }}
                    />
                  </StyledCompaniesAddBtn>
                </StyledCompaniesAddBtnWrap>
              </div>
            </div>
            <div className="row">
              {companiesPage.companiesPage.map((n: CompaniesDataType) => (
                <CompaniesCard
                  key={n.id}
                  companies={n}
                  staticData={dictionaries}
                />
              ))}
            </div>
            <section className="company-bottom">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="company-bottom__btn">
                      <button
                        type="button"
                        onClick={() => handleChangeCount(1)}
                        className="btn-yellow btn-company"
                      >
                        Следующая страница
                      </button>
                      <div className="company-bottom__footer">
                        <button
                          onClick={() => handleChangeCount(-1)}
                          type="button"
                          className="btn-left btn-arrow"
                        >
                          <svg
                            width="6"
                            height="10"
                            fill="none"
                            viewBox="0 0 6 10"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5 1C4.68 1.32 2.2 3.8 1 5L5 9"
                              stroke="#BDBDBD"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                        <div className="company-bottom__numb">{count}</div>
                        <button
                          type="button"
                          onClick={() => handleChangeCount(1)}
                          className="btn-right btn-arrow"
                        >
                          <svg
                            width="6"
                            height="10"
                            viewBox="0 0 6 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 1C1.32 1.32 3.8 3.8 5 5L1 9"
                              stroke="#828282"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                        <div className="company-bottom__all">
                          из<span>{companiesPage.totalCount}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </StyledCompaniesList>
      </div>
    </>
  );
};
export default Companies;
