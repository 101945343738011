import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledLinkComponent = styled(Link)<{
  $whiteflag: boolean | undefined;
  $borderflag: boolean | undefined;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border-radius: 3px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #000000;
  background-color: ${props => (props.$whiteflag ? '#ffffff' : '#fedc2d')};
  border: ${props => (props.$borderflag ? '1px solid #cdcede;' : 'none')};
  transition: all 0.3s ease;
  cursor: pointer;
  height: auto;
  width: 100%;

  svg {
    margin-right: 10px;
  }

  &:hover,
  &:focus {
    color: #000000;
    background-color: ${props => (props.$whiteflag ? '#ffffff' : '#ffe600')};
  }

  &:active {
    color: #000000;
    background-color: ${props => (props.$whiteflag ? '#ffffff' : '#ffe600')};
  }

  @media (max-width: 991px) {
    padding: 12px 16px;
    font-size: 14px;
  }
`;

interface CustomStyles {
  height?: string | number;
  padding?: string | number;
  fontSize?: string | number;
}

interface PropsInterface {
  text: string;
  path?: string;
  additionalClass?: string;
  $whiteBackground?: boolean;
  $border?: boolean;
  button?: boolean;
  inlineStyles?: CustomStyles;
  handleСlick?: () => void;
  disabled?: boolean;
  type?: any;
  dataToggle?: string;
  dataTarget?: string;
}

const StyledLink: React.FC<PropsInterface> = ({
  text,
  path,
  additionalClass,
  children,
  $whiteBackground,
  $border,
  button,
  inlineStyles,
  handleСlick,
  disabled,
  type,
  dataToggle,
  dataTarget,
}) => {
  return button ? (
    <StyledLinkComponent
      className={additionalClass}
      $whiteflag={$whiteBackground}
      $borderflag={$border}
      style={inlineStyles}
      onClick={handleСlick}
      disabled={disabled}
      type={type}
      as="button"
      data-toggle={dataToggle}
      data-target={dataTarget}
    >
      {children}
      {text}
    </StyledLinkComponent>
  ) : (
    <StyledLinkComponent
      className={additionalClass}
      to={path}
      $whiteflag={$whiteBackground}
      $borderflag={$border}
      style={inlineStyles}
      onClick={handleСlick}
    >
      {children}
      {text}
    </StyledLinkComponent>
  );
};

export default StyledLink;
