import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

import reportWebVitals from './reportWebVitals';
import store from './store';
import './assets/base.scss';
import './assets/modal.scss';
import './assets/flatpickr.scss';
import './assets/dflt.scss';
import './assets/buttons.scss';
import './assets/fields.scss';
import './assets/form-select.scss';
import './assets/company-display.scss';
import './assets/content-system-section.scss';

import './assets/index.scss';

import './assets/important.scss';
import './assets/actions.scss';
import './assets/statistic.scss';

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
