import React, { useEffect, useState } from 'react';
import './vacancyCVs.scss';
import { Modal } from 'react-bootstrap';
import { useActions } from '../../../hooks/useActions';
import VacancyCVsTable from './VacancyCVsTable';
import VacancyCVsAdd from './VacancyCVsAdd';

interface Params {
  id: string;
}

const VacancyCVs: React.FunctionComponent<Params> = ({ id }) => {
  const { fetchListCVS } = useActions();
  const [page, setPage] = useState(0);
  const [modal, setModal] = useState(false);
  const limit = 10;

  useEffect(() => {
    fetchListCVS(page, limit, id);
  }, [page, id]);

  const handleOpenModal = () => {
    setModal(true);
  };

  const reFetchCVS = () => {
    fetchListCVS(page, limit, id);
  };
  return (
    <>
      <Modal
        size="xl"
        show={modal}
        onHide={() => {
          setModal(false);
        }}
        className="modal fade addResume"
      >
        <Modal.Body className="big">
          <VacancyCVsAdd
            vacancyId={id}
            setModal={setModal}
            reFetchCVS={reFetchCVS}
          />
        </Modal.Body>
      </Modal>
      <div className="content-system-section">
        <div className="content-system-section__content">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-2">
                <h2 className="content-system-section__title">Резюме</h2>
              </div>
              <div className="col-lg-5">
                <form className="dflt-search" action="#">
                  <input
                    type="text"
                    name="search"
                    placeholder="Иванов Иван Петрович"
                  />
                  <button type="submit">
                    <svg
                      width="11"
                      height="11"
                      viewBox="0 0 11 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.4586 10.0193L7.84054 7.29639C8.51367 6.49619 8.88249 5.48938 8.88249 4.44125C8.88249 1.99238 6.89011 0 4.44125 0C1.99238 0 0 1.99238 0 4.44125C0 6.89011 1.99238 8.88249 4.44125 8.88249C5.36058 8.88249 6.23667 8.6052 6.98569 8.07882L9.6236 10.8224C9.73386 10.9369 9.88216 11 10.0411 11C10.1915 11 10.3342 10.9427 10.4425 10.8384C10.6727 10.6169 10.68 10.2496 10.4586 10.0193ZM4.44125 1.15859C6.25134 1.15859 7.72391 2.63115 7.72391 4.44125C7.72391 6.25134 6.25134 7.72391 4.44125 7.72391C2.63115 7.72391 1.15859 6.25134 1.15859 4.44125C1.15859 2.63115 2.63115 1.15859 4.44125 1.15859Z"
                        fill="#828282"
                      />
                    </svg>
                  </button>
                </form>
              </div>
              <div className="col-lg-5 text-lg-right">
                <div className="copple-btns">
                  <button
                    className="dflt-bttn dflt-bttn_action"
                    type="button"
                    onClick={handleOpenModal}
                  >
                    <svg
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.80117 3.83526C9.66855 3.70267 9.50748 3.6364 9.31815 3.6364H6.36357V0.681751C6.36357 0.49242 6.29725 0.331398 6.16471 0.198809C6.03215 0.0663194 5.87125 0 5.68164 0H4.31813C4.12878 0 3.96778 0.0662448 3.83519 0.198834C3.7026 0.331423 3.63633 0.492445 3.63633 0.681776V3.63642H0.681778C0.492446 3.63642 0.331424 3.70269 0.198834 3.83528C0.0662449 3.96787 0 4.12874 0 4.3182V5.68188C0 5.87138 0.0662201 6.03228 0.198809 6.16474C0.331399 6.29738 0.492422 6.3636 0.681753 6.3636H3.63631V9.3183C3.63631 9.50763 3.70258 9.66878 3.83517 9.80132C3.96776 9.93378 4.12875 10 4.31811 10H5.68172C5.8712 10 6.03212 9.93378 6.16473 9.80132C6.29737 9.6687 6.36359 9.50763 6.36359 9.3183V6.3636H9.31812C9.50763 6.3636 9.6686 6.29738 9.80114 6.16474C9.9337 6.03228 10 5.87138 10 5.68188V4.3182C9.99995 4.12874 9.9338 3.96777 9.80117 3.83526Z"
                        fill="#CDCEDE"
                      />
                    </svg>
                    Добавить кандидата
                  </button>
                  <div className="dflt-bttn_action-list">
                    <a
                      className="dflt-bttn dflt-bttn_action dflt-bttn__import mr-0"
                      href="/"
                    >
                      <svg
                        width="10"
                        height="11"
                        viewBox="0 0 10 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.98167 6.0979C9.94449 6.01223 9.85774 5.95633 9.76146 5.95633H7.14987V0.229097C7.14987 0.102639 7.04309 0 6.91153 0H3.09827C2.96671 0 2.85994 0.102639 2.85994 0.229097V5.95635H0.238333C0.142056 5.95635 0.0552975 6.01225 0.0181183 6.09747C-0.0185917 6.18315 0.00142778 6.28166 0.0695971 6.34719L4.82424 10.9326C4.86904 10.9757 4.92959 11 4.99298 11C5.05637 11 5.11692 10.9757 5.16172 10.9331L9.93019 6.34764C9.99836 6.28209 10.0189 6.1836 9.98167 6.0979Z"
                          fill="#CDCEDE"
                        />
                      </svg>
                      Импорт списка
                    </a>
                    <div className="dflt-bttn_action-block">
                      <div className="dflt-bttn_action-block__title">
                        Импорт списка
                      </div>
                      <div className="dflt-bttn_action-block__desc">
                        Загружать файл в формате (csv)
                      </div>
                      <a
                        className="dflt-bttn_action-block__desc dflt-bttn_action-block__link"
                        href="/"
                      >
                        Скачать пример файла
                      </a>
                      <a className="dflt-bttn__download" href="/">
                        <img src="./image/download-icon.svg" alt="#" />
                        перенести файл
                      </a>
                      <a className="dflt-bttn" href="/">
                        Скачать
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content-system-section__content">
          <VacancyCVsTable page={page} setPage={setPage} />
        </div>
      </div>
    </>
  );
};
export default VacancyCVs;
