export const wordsDict = {
  CANDIDATES_FORMS: ['кандидат', 'кандидата', 'кандидатов'],
  QUESTIONNARIES_FORMS: ['анкета', 'анкеты', 'анкет'],
  YEARS_FORMS: ['год', 'года', 'лет'],
};

export const wordDeclination = (number: number, words: Array<string>) => {
  return words[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? number % 10 : 5]
  ];
};
