import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './viewCompany.scss';
import ViewCompanyCard from './ViewCompanyCard/ViewCompanyCard';
import useTypedSelector from '../../../hooks/useTypedSelector';
import { CompanyStaff } from '../../../types/companies';
import { useActions } from '../../../hooks/useActions';
import ViewCompanyForm from './ViewCompanyForm/ViewCompanyForm';
import FormHeader from '../FormHeader/FormHeader';

interface Params {
  id: string;
}

const ViewCompany: React.FunctionComponent = () => {
  const { staffId, staff, staffChange } = useTypedSelector(r => r.companies);
  const { dictionaries } = useTypedSelector(r => r.mainData);
  const params: Params = useParams();
  const {
    fetchCompanyStaff,
    resetStaff,
    fetchStaffId,
    deleteCompanyStaff,
  } = useActions();
  const [modal, setModal] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  useEffect(() => {
    fetchCompanyStaff(params.id);
    setModal(false);
  }, [staffChange]);

  const handleEditStaff = (value: string) => {
    fetchStaffId(value);
    setModal(true);
    setEdit(true);
  };
  const handleDeleteStaff = (value: string) => {
    deleteCompanyStaff(value, params.id);
  };
  const addStaffFunc = () => {
    setModal(true);
    resetStaff();
  };

  return (
    <>
      <FormHeader text="Редактировать компанию" editCompany />
      <section className="company-amount">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-4">
              <div className="company-amount__title">
                Всего: <span>{staff.length}</span>
                сотрудников
              </div>
            </div>
            <div className="col-xl-7 col-lg-8">
              <div className="company-amount__block">
                <button
                  onClick={() => addStaffFunc()}
                  className="btn-yellow btn-company"
                  data-toggle="modal"
                  data-target="#exampleModal"
                  type="button"
                >
                  <span>
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <ellipse
                        cx="6.95674"
                        cy="7"
                        rx="6.95674"
                        ry="7"
                        fill="black"
                      />
                      <path
                        d="M6.95639 5V9M8.94403 7L4.96875 7"
                        stroke="#FEDC2D"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                    </svg>
                  </span>
                  Добавить сотрудника
                </button>
                <div className="search-block">
                  <input
                    className="search-input"
                    id="search-input"
                    type="text"
                    placeholder="Имя сотрудника"
                  />
                  <label className="label-search" htmlFor="search-input">
                    <img src="./image/loop.svg" alt="#" />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="company-table desktop">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="company-table__top">
                <div className="company-table__row">
                  <div className="company-table__row-item">ФИО</div>
                  <div className="company-table__row-item">Роль</div>
                  <div className="company-table__row-item">Вакансии</div>
                  <div className="company-table__row-item">Собеседования</div>
                  <div className="company-table__row-item">Анкеты</div>
                  <div className="company-table__row-item last" />
                </div>
              </div>
              {dictionaries.ruleAction &&
                staff.map((n: CompanyStaff) => (
                  <ViewCompanyCard
                    staticData={dictionaries}
                    handleDeleteStaff={handleDeleteStaff}
                    handleEditStaff={handleEditStaff}
                    staff={n}
                    key={n.user_id}
                  />
                ))}
            </div>
          </div>
        </div>
      </section>
      {staff.length > 10 && (
        <section className="company-bottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="company-bottom__btn">
                  <a className="btn-yellow btn-company" href="/">
                    Следующая страница
                  </a>
                  <div className="company-bottom__footer">
                    <a className="btn-left btn-arrow" href="/">
                      <svg
                        width="6"
                        height="10"
                        fill="none"
                        viewBox="0 0 6 10"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5 1C4.68 1.32 2.2 3.8 1 5L5 9"
                          stroke="#BDBDBD"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </a>
                    <div className="company-bottom__numb">1</div>
                    <a className="btn-right btn-arrow" href="/">
                      <svg
                        width="6"
                        height="10"
                        viewBox="0 0 6 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 1C1.32 1.32 3.8 3.8 5 5L1 9"
                          stroke="#828282"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </a>
                    <div className="company-bottom__all">
                      из<span>59</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <ViewCompanyForm
        staticData={dictionaries}
        companyId={params.id}
        staffId={staffId}
        edit={edit}
        setModal={setModal}
        modal={modal}
      />
    </>
  );
};

export default ViewCompany;
